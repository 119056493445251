import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RelatorioResponse } from '../../models/relatorio/trimestral/RelatorioResponse';
import { Observable } from 'rxjs';
import { AtividadesResponse } from '../../models/relatorio/atividades/AtividadesResponse';
import { MetasResponse } from '../../models/relatorio/metas/MetasResponse';

@Injectable({
    providedIn: 'root',
})
export class RelatorioService {
    relatoriosUrl = environment.API_URL + 'relatorio';
    constructor(private http: HttpClient) {}

    private setHeadersConfiguration() {
        let token = sessionStorage.getItem('token');
        return new HttpHeaders({
            secret: environment.API_SECRET,
            Authorization: `Bearer ${token}`,
        });
    }

    gerarRelatorio(codigo: number, data: Date) {
        return this.http.get(
            this.relatoriosUrl +
                `/municipio/${codigo}/${data.toISOString().slice(0, 10)}`,
            {
                headers: this.setHeadersConfiguration(),
            }
        );
    }

    obterRelatorioDashBoard(data: Date, tipoRelatorio: string) {
        return this.http.get(
            this.relatoriosUrl +
                `/dash`,
            {
                headers: this.setHeadersConfiguration(),
                params: {
                    data: data.toISOString().slice(0, 10),
                    tipoRelatorio: tipoRelatorio
                }
            }
        );
    }

    consolidadoMensal(data: Date){
        const headers = this.setHeadersConfiguration();
        return this.http.get(this.relatoriosUrl + `/mensal?mes=${data.getMonth()+1}&ano=${data.getFullYear()}`, {
            headers: headers
        })
    }

    consolidadoTrimestral(data: Date) : Observable<RelatorioResponse>{
        const headers = this.setHeadersConfiguration();
        return this.http.get<RelatorioResponse>(this.relatoriosUrl + `/trimestral?mes=${data.getMonth()+1}&ano=${data.getFullYear()}`, {
            headers: headers
        })
    }

    consolidadoAtividades(data: Date): Observable<AtividadesResponse>{
        const headers = this.setHeadersConfiguration();
        return this.http.get<AtividadesResponse>(this.relatoriosUrl + `/atividades?mes=${data.getMonth()+1}&ano=${data.getFullYear()}`, {
            headers: headers
        })
    }

    consolidadoMetas(data: Date): Observable<MetasResponse>{
        const headers = this.setHeadersConfiguration();
        return this.http.get<MetasResponse>(this.relatoriosUrl + `/metas?mes=${data.getMonth()+1}&ano=${data.getFullYear()}`, {
            headers: headers
        })
    }
}
