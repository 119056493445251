<div class="rightpanel-section mt-4">
    <div class="flex align-items-center justify-content-between mb-3">
        <h6 class="m-0">Telefones úteis</h6>
        <!-- <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-secondary p-button-text p-button-rounded" (click)="showForm = true"></button> -->
    </div>
    <!-- @if (showForm) {
        <app-form-sector-information (sectorEmitter)="addSectorInformation($event)" (cancelEmitter)="showForm = false"></app-form-sector-information>
    } -->
    <ul class="list-none p-0 m-0 overflow-auto" [style]="spnh != null ? 'max-height: 400px' : ''">
        @for (sector of this.sectorsInformation; track sector.title) {
            <li class="p-3 border-round-lg surface-0 mb-3 flex align-items-center justify-content-between">
            <div class="flex-grow-1">
                <h6 class="m-0 mb-2 text-color">{{ sector.title }}</h6>
                <span class="text-400 line-height-3 block font-medium">{{ sector.phone1 }}</span>
                <span class="text-400 line-height-3 block font-medium">{{ sector.phone2 }}</span>
                <a class="line-height-3 block font-medium" *ngIf="sector.website" [href]="sector.website" target="_blank">Site</a>
            </div>
            <button pButton pRipple type="button" icon="pi pi-copy" class="p-button-secondary p-button-text p-button-rounded" (click)="copyToClipboard(sector)"></button>
            </li>
        }
    </ul>

    @if(spnh != null){
        @if(spnh.responsavel != null){
            <div class="flex align-items-center justify-content-between mb-3 mt-5">
                <h6 class="m-0">Meu SPNH</h6>
            </div>

            <ul class="list-none p-0 m-0 overflow-auto" style="max-height: 330px">
                <li class="p-3 border-round-lg surface-0 mb-3 flex align-items-center justify-content-between">
                <div class="flex-grow-1">
                    <h6 class="m-0 mb-2 text-color">{{ spnh.responsavel.dadosCadastrais.nome }}
                        <span class="line-height-3 block font-normal m-0"><small>(Responsável)</small></span>
                    </h6>
                    <span class="text-500 line-height-3 block font-medium">{{ spnh.responsavel.dadosCadastrais.telefone }}</span>
                    <span class="text-500 line-height-3 block font-medium">{{ spnh.responsavel.email }}</span>
                    <!-- <a class="line-height-3 block font-medium" *ngIf="sector.website" [href]="sector.website" target="_blank">FESF</a> -->
                </div>
                <!-- <button pButton pRipple type="button" icon="pi pi-copy" class="p-button-secondary p-button-text p-button-rounded"></button> -->
                </li>
            </ul>
        } @else {
            <div class="flex align-items-center justify-content-between mb-3 mt-6">
                <h6 class="m-0">SPNH</h6>
            </div>

            <ul class="list-none p-0 m-0 overflow-auto" style="max-height: 330px">
                <li class="p-3 border-round-lg surface-0 mb-3 flex align-items-center justify-content-between">
                <div class="flex-grow-1">
                    <h6 class="m-0 mb-2 text-color">O serviço ainda não possui um responsável</h6>
                </div>
                </li>
            </ul>
        }
    }

</div>
