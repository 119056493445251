import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Gpq } from 'src/app/application/models/Gpq';
import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { GpqStatusService } from 'src/app/application/services/gpq-status-service/gpq-status.service';
import { UserService } from 'src/app/application/services/user-service/user.service';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { UserRole } from 'src/app/core/models/UserRoles';
import { DataFormatService } from 'src/app/core/service/format-service/data-format/data-format.service';

@Component({
    selector: 'app-gpq-selecionado',
    templateUrl: './gpq-selecionado.component.html',
    styleUrl: './gpq-selecionado.component.scss',
})
export class GpqSelecionadoComponent implements OnInit, OnDestroy {
    gpq: Gpq | null = null;
    private gpqSub!: Subscription;
    usuario: any;

    constructor(
        private gpqService: GpqService,
        private userService: UserService,
        private router: Router,
        private gpqStatusService: GpqStatusService,
        public dataFormat: DataFormatService
    ) {}

    ngOnInit(): void {
        this.gpqSub = this.gpqService.getGpq(
            (gpq) => (this.gpq = gpq ? gpq : null)
        );
        const token = sessionStorage.getItem('token');

        if(token){
            this.userService.buscarUsuarioAutenticado()
            .subscribe({
                next: (response) => {
                    this.usuario = response;
                },
                error: (err) => {
                    this.usuario = null;
                }
            });
        }

    }

    ngOnDestroy(): void {
        this.gpqSub.unsubscribe();
    }

    getSeverityStatus(status: string) {
        return this.gpqStatusService.getSeverity(status)
    }

    redirectToHome() {
        let role: string | null = sessionStorage.getItem('role');

        if(this.gpq){
            role = role!.toLowerCase();

            const statusAction = this.changeVisibility[this.formatarStatus(this.gpq.statusDoDocumento)] || this.changeVisibility['default'];
            const action = statusAction[role!] || statusAction['default'];
            sessionStorage.setItem('role-gpq', action);
            if(role === "enfermeira" && this.gpq.statusDoDocumento === StatusGpq.Criado || role === "enfermeira" && this.gpq.statusDoDocumento === StatusGpq.Rejeitado){
                this.router.navigate(['formulario-gpq']);
            }
            else{
                this.router.navigate(['validar-gpq']);
            }
            return
        }

        switch (role) {
            case UserRole.ENFERMEIRA:
                this.router.navigate(['home-enfermeira']);
                break;

            case UserRole.SMS:
                this.router.navigate(['home-ssm']);
                break;

            case UserRole.FESF:
                this.router.navigate(['home-fesf']);
                break;

            case UserRole.SESAB:
                this.router.navigate(['home-sesab']);
                break;
        }
    }

    changeVisibility: { [key: string]: { [key: string]: string } }  = {
        criado: {
            enfermeira: 'observador',
            default: 'observador'
        },
        rejeitado: {
            enfermeira: 'observador',
            default: 'observador'
        },
        pendentemunicipio: {
            ssm: 'validador',
            default: 'observador'
        },
        pendentefesf: {
            fesf: 'validador',
            default: 'observador'
        },
        pendentesesab: {
            sesab: 'validador',
            default: 'observador'
        },
        default:{
            default: 'observador'
        }
    };

    formatarStatus(status: string): string{
        return status.replaceAll(' ', '').toLowerCase();
    }

    getStatusExibicao(status: string){
        return this.gpqStatusService.getStatusExibicao(status);
    }
}
