import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginForm } from 'src/app/application/models/LoginForm';
import { LoginService } from 'src/app/application/services/login-service/login.service';
import { UserRole } from 'src/app/core/models/UserRoles';
import { LayoutService } from 'src/app/core/service/app.layout.service';
import { PartoHumanizadoMessageService } from 'src/app/core/service/parto-humanizado-message.service';


@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
    rememberMe: boolean = false;
    loginForm: FormGroup<LoginForm>

    constructor(private layoutService: LayoutService, private loginService: LoginService, private messageService: PartoHumanizadoMessageService, private router: Router) {
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            senha: new FormControl('', Validators.required)
        })
        this.loginForm.get("email");
    }

    ngOnInit(): void {
        sessionStorage.clear();
    }

    onSubmit(){
        if(this.loginForm.valid){
            this.loginService.login(this.loginForm.value.email, this.loginForm.value.senha).subscribe({
                next: (response) => {
                    this.messageService.showSuccessMessage('Sucesso', 'Login realizado com sucesso!')
                    this.navigate(response.role);

                },
                error: (response) => {
                    this.messageService.showErrorMessage('Erro', response.error.message)
                }
            });
        } else {
            this.messageService.showInfoMessage('Preencha adequadamente todos os campos')
        }

    }

    private navigate(role: string){
        switch(role){
            case UserRole.ENFERMEIRA:
                return this.router.navigate(["home-enfermeira"])
            case UserRole.FESF:
                return this.router.navigate(["home-fesf"])
            case UserRole.SMS:
                return this.router.navigate(['home-ssm'])
            case UserRole.SESAB:
                return this.router.navigate(["home-sesab"])
            case UserRole.ADMIN:
                return this.router.navigate(["home-admin"])
            default:
                return this.router.navigate(['home-fesf'])
        }
      }

    get dark(): boolean {
        return this.layoutService.config().colorScheme !== 'light';
    }
}
