import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Gpq } from 'src/app/application/models/Gpq';
import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { GpqStatusService } from 'src/app/application/services/gpq-status-service/gpq-status.service';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { DataFormatService } from 'src/app/core/service/format-service/data-format/data-format.service';

@Component({
  selector: 'app-home-ssm',
  templateUrl: './home-ssm.component.html',
  styleUrl: './home-ssm.component.scss'
})
export class HomeSsmComponent implements OnInit, OnDestroy{

  listaGpq: Gpq[] = []
  listaGpqPendente: Gpq[] = []
  gpqSelecionado: Gpq | null = null;
  private gpqSub!: Subscription;

  constructor(
    private gpqService: GpqService, 
    private router: Router, 
    private gpqStatusService: GpqStatusService,
    public dataFormat: DataFormatService
  ){}

  ngOnInit(): void {
    this.gpqService.listarGpqPeloUsuarioMunicipal().subscribe({
      next: (response: Gpq[]) => {
        this.listaGpq = response
        this.filtrarListaGpq()
      }
    })

    this.gpqSub = this.gpqService.getGpq((gpq) => this.gpqSelecionado = gpq ? gpq : null)
  }

  ngOnDestroy(): void {
    this.gpqSub.unsubscribe()
  }

  private filtrarListaGpq(){
    this.listaGpqPendente = this.listaGpq.filter((gpq) => gpq.statusDoDocumento == StatusGpq.PendenteMunicipio)
  }

  selecionaGpq(gpq: Gpq){
    this.gpqService.selecionarGpq(gpq)
  }

  getSeverityStatus(status: string){
    return this.gpqStatusService.getSeverity(status)
  }

  navigateValidarGpq(gpq: Gpq) {
    this.selecionaGpq(gpq);
    let role = gpq.statusDoDocumento == StatusGpq.PendenteMunicipio ? "validador" : "observador"
    sessionStorage.setItem('role-gpq', role)
    this.router.navigate(
      ['/validar-gpq'],
    )
  }

  getStatusExibicao(status: string){
    return this.gpqStatusService.getStatusExibicao(status)
  }
}
