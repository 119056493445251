<div class="col-12">
    <div class="grid" style="margin: -1rem">
        <!-- Quantidades de GPQs aprovados -->
        <div class="col-12 sm:col">
            <div class="card surface-card text-500 flex justify-content-between pt-4 h-full" style="min-width: 17rem">
                <div class="overview-info">
                    <h6 class="m-0 mb-1 text-500">GPQs aprovadas</h6>
                    <h1 class="m-0 text-500">{{ quantidadeGpqsAprovados }}</h1>
                </div>
                <i class="pi pi-file-pdf text-3xl"></i>
            </div>
        </div>

        <!-- Quantidade de atendimentos realizados -->
        <div class="col-12 sm:col">
            <div class="card text-0 flex justify-content-between pt-4 h-full" style="background-color: var(--primary-color); min-width: 17rem">
                <div class="overview-info">
                    <h6 class="m-0 mb-1 text-0">Gestantes com vinculação</h6>
                    <h1 class="m-0 text-0">{{ quantidadeGestantesComVinculacao }}</h1>
                </div>
                <i class="pi pi-bookmark text-3xl"></i>
            </div>
        </div>

        <!-- Quantidade de grávidas em acompanhamento -->
        <div class="col-12 sm:col">
            <div class="card bg-gray-400 text-white flex justify-content-between pt-4 h-full" style="min-width: 17rem">
                <div class="overview-info">
                    <h6 class="m-0 mb-1 text-white">Gestantes durante o ano</h6>
                    <h1 class="m-0 text-white">{{ quantidadeDeGravidasEmAcompanhamento }}</h1>
                </div>
                <i class="pi pi-envelope text-3xl"></i>
            </div>
        </div>

        <!-- Partos esperados no próximo mês -->
        <div class="col-12 sm:col">
            <div class="card bg-gray-600 text-white flex justify-content-between pt-4 h-full" style="min-width: 17rem">
                <div class="overview-info">
                    <h6 class="m-0 mb-1 text-white">Partos previstos durante o ano</h6>
                    <h1 class="m-0 text-white">{{ quantidadeDePartosPrevistos }}</h1>
                </div>
                <i class="pi pi-clock text-3xl"></i>
            </div>
        </div>

        <!-- Quantidade de pessoas com riscos de gestação -->
        <div class="col-12 sm:col">
            <div class="card text-white flex justify-content-between pt-4 h-full" style="background: linear-gradient(90deg, #ffb340 0%, #ffa740 100%); min-width: 17rem">
                <div class="overview-info">
                    <h6 class="m-0 mb-1 text-white">Gestantes com riscos de gestação</h6>
                    <h1 class="m-0 text-white">{{ quantidadePessoasComRiscos  }}</h1>
                </div>
                <i class="pi pi-exclamation-triangle text-3xl"></i>
            </div>
        </div>
    </div>
</div>
