import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-form-sector-information',
    templateUrl: './form-sector-information.component.html',
    styleUrls: ['./form-sector-information.component.scss']
})
export class FormSectorInformationComponent implements OnInit {

    @Output() sectorEmitter = new EventEmitter();
    @Output() cancelEmitter = new EventEmitter();
    sectorForm!: FormGroup;

    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        this.buildForm();
    }

    onSubmit() {
        if (this.sectorForm.valid) {
            this.sectorEmitter.emit(this.sectorForm.value);
            this.sectorForm.reset();
        }
    }

    cancel() {
        this.cancelEmitter.emit();
    }

    private buildForm(): void {
        this.sectorForm = this.fb.group({
            title: ['', Validators.required],
            phone1: ['', [Validators.required]],
            phone2: ['', []],
            address: ['', []]
        });
    }

}
