import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from 'src/app/core/components/layout/app.layout.component';
import { HistoricoGpqComponent } from './application/pages/private/historico-gpq/historico-gpq.component';
import { HomeEnfermeiraComponent } from './application/pages/private/home-enfermeira/home-enfermeira.component';
import { HomeSsmComponent } from './application/pages/private/home-ssm/home-ssm.component';
import { HomeComponent } from './application/pages/private/home/home.component';
import { ValidarGpqComponent } from './application/pages/private/validar-gpq/validar-gpq.component';
import { AccessdeniedComponent } from './application/pages/public/auth/accessdenied/accessdenied.component';
import { LoginComponent } from './application/pages/public/auth/login/login.component';
import { NotfoundComponent } from './application/pages/public/notfound/notfound.component';
import { UserRole } from './core/models/UserRoles';
import { authGuard } from './core/service/guards/auth.guard';
import { userRoleGuard } from './core/service/guards/user-role.guard';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
};

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'login',
        component: LoginComponent,
        loadChildren: () =>
            import('./application/pages/public/auth/login/login.module').then(
                (m) => m.LoginModule
            ),
    },
    {
        path: '',
        component: AppLayoutComponent,
        canActivateChild: [authGuard],
        children: [
            {
                path: 'users',
                canActivate: [userRoleGuard],
                data: {
                    roles: [
                    UserRole.FESF,
                    UserRole.SESAB,
                    UserRole.ADMIN,
                    ]
                },
                loadChildren: () =>
                    import(
                        './application/pages/private/usuario/usuario.module'
                    ).then((m) => m.UsuarioModule),
            },
            {
                path: 'formulario-gpq',
                loadChildren: () =>
                    import(
                        './application/pages/private/formulario-gpq/formulario-gpq.module'
                    ).then((m) => m.FormularioGpqModule),
            },
            {
                path: 'home-sesab',
                component: HomeComponent,
                canActivate: [userRoleGuard],
                data: { roles: [UserRole.SESAB] },
                loadChildren: () =>
                    import('./application/pages/private/home/home.module').then(
                        (m) => m.HomeModule
                    ),
            },
            {
                path: 'home-ssm',
                component: HomeSsmComponent,
                canActivate: [userRoleGuard],
                data: { roles: [UserRole.SMS] },
                loadChildren: () =>
                    import(
                        './application/pages/private/home-ssm/home-ssm.module'
                    ).then((m) => m.HomeSsmModule),
            },
            {
                path: 'home-fesf',
                component: HomeComponent,
                canActivate: [userRoleGuard],
                data: { roles: [UserRole.FESF]},
                loadChildren: () =>
                    import('./application/pages/private/home/home.module').then(
                        (m) => m.HomeModule
                    ),
            },
            {
                path: 'home-admin',
                component: HomeComponent,
                canActivate: [userRoleGuard],
                data: { roles: [UserRole.ADMIN] },
                loadChildren: () =>
                    import('./application/pages/private/home/home.module').then(
                        (m) => m.HomeModule
                    ),
            },
            {
                path: 'home-enfermeira',
                component: HomeEnfermeiraComponent,
                canActivate: [userRoleGuard],
                data: { roles: [UserRole.ENFERMEIRA] },
                loadChildren: () =>
                    import(
                        './application/pages/private/home-enfermeira/home-enfermeira.module'
                    ).then((m) => m.HomeEnfermeiraModule),
            },
            {
                path: 'pessoas',
                canActivateChild: [userRoleGuard],
                data: {
                    roles: [
                        UserRole.ENFERMEIRA,
                        UserRole.FESF,
                        UserRole.SESAB,
                        UserRole.SMS,
                        UserRole.ADMIN,
                    ],
                },
                loadChildren: () =>
                    import(
                        './application/pages/private/pessoa/pessoa.module'
                    ).then((m) => m.PessoaModule),
            },
            {
                path: 'validar-gpq',
                component: ValidarGpqComponent,
                loadChildren: () =>
                    import(
                        './application/pages/private/validar-gpq/validar-gpq.module'
                    ).then((m) => m.ValidarGpqModule),
            },
            {
                path: 'historico-gpq',
                component: HistoricoGpqComponent,
                loadChildren: () =>
                    import(
                        './application/pages/private/historico-gpq/historico-gpq.module'
                    ).then((m) => m.HistoricoGpqModule),
            },
            {
                path: 'partos',
                canActivateChild: [userRoleGuard],
                data: {
                    roles: [
                        UserRole.ENFERMEIRA,
                        UserRole.FESF,
                        UserRole.SESAB,
                        UserRole.SMS,
                        UserRole.ADMIN,
                    ],
                },
                loadChildren: () =>
                    import(
                        './application/pages/private/partos-do-mes/partos-do-mes.module'
                    ).then((m) => m.PartosDoMesModule),
            },
            {
                path: 'visita/:tipoVisita',
                canActivateChild: [userRoleGuard],
                data: {
                    roles: [
                        UserRole.ENFERMEIRA,
                        UserRole.FESF,
                        UserRole.SESAB,
                        UserRole.SMS,
                        UserRole.ADMIN,
                    ],
                },
                loadChildren: () =>
                    import(
                        './application/pages/private/visita/visita.module'
                    ).then((m) => m.VisitaModule),
            },
            {
                path: 'riscos/:tipoRisco',
                canActivateChild: [userRoleGuard],
                data: {
                    roles: [
                        UserRole.ENFERMEIRA,
                        UserRole.FESF,
                        UserRole.SESAB,
                        UserRole.SMS,
                        UserRole.ADMIN,
                    ],
                },
                loadChildren: () =>
                    import(
                        './application/pages/private/riscos/risco.module'
                    ).then((m) => m.RiscosModule),
            },
            {
                path: 'reuniao/:tipoReuniao',
                canActivateChild: [userRoleGuard],
                data: {
                    roles: [
                        UserRole.ENFERMEIRA,
                        UserRole.FESF,
                        UserRole.SESAB,
                        UserRole.SMS,
                        UserRole.ADMIN,
                    ],
                },
                loadChildren: () =>
                    import(
                        './application/pages/private/reuniao/reuniao.module'
                    ).then((m) => m.ReuniaoModule),
            },
            {
                path: 'relatorio-mensal',
                loadChildren: () =>
                    import(
                        './application/pages/private/relatorio-mensal/relatorio-mensal.module'
                    ).then((m) => m.RelatorioMensalModule),
            },
            {
                path: 'spnh',
                loadChildren: () =>
                    import(
                        './application/pages/private/spnh/spnh.module'
                    ).then((m) => m.SpnhModule),
            },
            {
                path: 'relatorio-consolidado',
                canActivate: [userRoleGuard],
                data: {
                    roles: [
                        UserRole.FESF,
                        UserRole.SESAB,
                        UserRole.ADMIN,
                    ] },
                loadChildren: () =>
                    import(
                        './application/pages/private/relatorio-consolidado/relatorio-consolidado.module'
                    ).then((m) => m.RelatorioConsolidadoModule),
            },
            {
                path: 'relatorio-consolidado-graficos',
                canActivate: [userRoleGuard],
                data: {
                    roles: [
                        UserRole.FESF,
                        UserRole.SESAB,
                        UserRole.ADMIN,
                    ]
                },
                loadChildren: () =>
                    import(
                        './application/pages/private/relatorio-consolidado(teste-graficos)/relatorio-consolidado.module'
                    ).then((m) => m.RelatorioConsolidadoModule),
            },
            {
                path: 'perfil',
                loadChildren: () =>
                    import(
                        './application/pages/private/perfil-usuario/perfil-usuario.module'
                    ).then((m) => m.PerfilUsuarioModule),
            },
            {
                path: 'metas',
                loadChildren: () =>
                    import(
                        './application/pages/private/metas/metas.module'
                    ).then((m) => m.MetasModule),
            },
            {
                path: 'sobre',
                loadChildren: () =>
                    import(
                        './application/pages/private/sobre/sobre.module'
                    ).then((m) => m.SobreModule),
            },
        ],
    },
    {
        path: 'not-found',
        component: NotfoundComponent,
    },
    {
        path: 'access-denied',
        component: AccessdeniedComponent,
    },
    {
        path: 'alterar-senha',
        loadChildren: () =>
            import('./application/pages/public/auth/change-password/change-password.module').then(
                (m) => m.ChangePasswordModule
            ),
    },
    { path: '**', redirectTo: '/not-found' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
