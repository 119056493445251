<div class="card">
    <div class="col-12">
        <div class="grid flex-container" style="margin: 1rem">
            @for(gpq of listaGpqPendente; track gpq.numeroDoDocumento){
                <div class="card surface-card text-500 flex justify-content-between pt-4 h-full" style="min-width: 17rem; cursor: pointer;" (click)="navigateValidarGpq(gpq)">
                    <div class="overview-info">
                        <h6 class="m-0 mb-1 text-500">{{dataFormat.formatDataToStringMesAno(gpq.dataReferente)}}</h6>
                        <h1 class="m-0 text-500">{{getStatusExibicao(gpq.statusDoDocumento)}}</h1>
                    </div>
                    <i class="pi pi-file-pdf text-3xl"></i>
                </div>
                } @empty {
                    Não há GPQs pendentes da sua aprovação
                }
        </div>
    </div>
    
    <div class="card">
        <p-table [value]="listaGpq" selectionMode="single" [(selection)]="gpqSelecionado" sortField="data" [tableStyle]="{ 'min-width': '50rem' }"styleClass="p-datatable-gridlines">
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th style="width: 50%; text-align: center;">Mês/Ano referente</th>
                <th style="width: 50%; text-align: center;">Status</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-gpq>
            <tr>
    
                @if (gpq.statusDoDocumento != 'Ferias' && gpq.statusDoDocumento != 'Bloqueado') {
                    <td><p-tableRadioButton [value]="gpq" (click)="selecionaGpq(gpq)" /></td>
                    <td style="cursor: pointer;" (click)="navigateValidarGpq(gpq)">{{ dataFormat.formatDataToStringMesAno(gpq.dataReferente) }}</td>
                    <td  style="cursor: pointer;" (click)="navigateValidarGpq(gpq)"><p-tag [severity]="getSeverityStatus(gpq.statusDoDocumento)" [value]="getStatusExibicao(gpq.statusDoDocumento)"/></td>
    
                }@else {
                    <td></td>
                    <td>{{ dataFormat.formatDataToStringMesAno(gpq.dataReferente) }}</td>
                    <td><p-tag [severity]="getSeverityStatus(gpq.statusDoDocumento)" [value]="getStatusExibicao(gpq.statusDoDocumento)"/></td>
    
                }
    
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>
