import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, Subscription } from 'rxjs';
import { Gpq } from 'src/app/application/models/Gpq';

import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { GpqStatusService } from 'src/app/application/services/gpq-status-service/gpq-status.service';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { DataFormatService } from 'src/app/core/service/format-service/data-format/data-format.service';
import { PartoHumanizadoMessageService } from 'src/app/core/service/parto-humanizado-message.service';

@Component({
    selector: 'app-home-enfermeira',
    templateUrl: './home-enfermeira.component.html',
    styleUrl: './home-enfermeira.component.scss',
})
export class HomeEnfermeiraComponent implements OnInit, OnDestroy {
    listaGpq!: Gpq[];

    gpqSelecionado: Gpq | null = null;
    private gpqSub!: Subscription;
    CriandoGPQ = signal(false);

    visible: boolean = false;
    inputText: string = '';
    expectedText: string = 'Não houve GPQ'
    buttons: any [] = []
    splitButtonAcoes = [
        { label: 'Não houve GPQ', icon: 'pi pi-times', command: () => this.showDialog() }
      ];

    constructor(
        private router: Router,
        private gpqService: GpqService,
        private messageService: PartoHumanizadoMessageService,
        private gpqStatusService: GpqStatusService,
        public dataFormat: DataFormatService
    ) {}

    ngOnInit(): void {
        this.gpqService
            .listarGpqEnfermeira()
            .subscribe((data: Gpq[]) => (this.listaGpq = data)),
            (this.gpqSub = this.gpqService.getGpq(
                (gpq) => {
                    this.gpqSelecionado = gpq ? gpq : null
                }
            ));
    }

    ngOnDestroy(): void {
        this.gpqSub.unsubscribe();
    }

  navigateToFormularioGpq(gpq: Gpq) {
    this.gpqService.selecionarGpq(gpq)
    if (gpq.statusDoDocumento != StatusGpq.Criado && gpq.statusDoDocumento != StatusGpq.Rejeitado){
        let role = "observador"
        sessionStorage.setItem('role-gpq', role)
        this.router.navigate(
            ['/validar-gpq'],
        )
    }
    else this.router.navigate(["formulario-gpq/"]);
  }

    selecionarGpq(gpq: Gpq) {
        this.gpqService.selecionarGpq(gpq);
    }

    criarNovoGpq() {
        if (!this.CriandoGPQ) return;

        this.CriandoGPQ.set(true);
        this.gpqService
            .criarNovoGpq()
            .pipe(finalize(() => this.CriandoGPQ.set(false)))
            .subscribe({
                next: (response) => {
                    this.messageService.showSuccessMessage('Sucesso', 'GPQ criada com sucesso!');
                    this.clearSelection();
                    this.listaGpq = [...this.listaGpq, response];
                },
                error: (response) => {
                    if(response.error && response.error.message){
                        this.messageService.showErrorMessage('Erro', response.error.message);
                    } else {
                        this.messageService.showErrorMessage('Algo deu errado', 'Tente novamente mais tarde.');
                    }
                },
            });
    }

    criarGpqFerias() {
        if (!this.CriandoGPQ) return;

        this.CriandoGPQ.set(true);
        this.gpqService
            .criarGpqFerias()
            .pipe(finalize(() => this.CriandoGPQ.set(false)))
            .subscribe({
                next: (response) => {
                    this.messageService.showSuccessMessage('Sucesso', 'Operação realizada com sucesso');
                    this.clearSelection();
                    this.listaGpq = [...this.listaGpq, response];
                },
                error: (response) => {
                    this.messageService.showErrorMessage('Erro', response.error.message);
                },
            });
    }

    verifyText(inputValue: string) {
        if (this.normalizeString(inputValue) === this.normalizeString(this.expectedText)) {
            this.criarGpqFerias();
            this.visible = !this.visible
        } else {
          this.messageService.showErrorMessage('Erro','O texto não corresponde!');
        }
    }

    normalizeString(str: string): string {
        return str.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/["']/g, '')
          .toLowerCase();
    }

    filtrarPeloMes(date: Date) {
        this.gpqService
            .listarGpqEnfermeiraDadoMes(date)
            .subscribe((data: Gpq[]) => (this.listaGpq = data));
    }

    clearSelection(): void {
        this.gpqService
            .listarGpqEnfermeira()
            .subscribe((data: Gpq[]) => (this.listaGpq = data));
    }

    getSeverityStatus(status: string) {
        return this.gpqStatusService.getSeverity(status)
    }

    getStatusGpq(status: string) {
        return this.gpqStatusService.getStatusExibicao(status);
    }

    showDialog(){
        this.visible = !this.visible
    }
}
