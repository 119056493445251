
@if(statusGpq == "Ferias"){
    <div class="card">
        <app-header-com-botoes
            [title]="'GPQ ' + data"
            [buttons]="buttonsGpqStatusFerias">
        </app-header-com-botoes>

        <div style="padding: 16px;">Não houve GPQ no mês {{data}}</div>
    </div>

}@else{
    <div class="card">
        <app-header-com-botoes
        [title]="'Avaliação GPQ ' + data"
        [buttons]="buttons"
        [showMainButtons]="{ history: true, return: true }">
        </app-header-com-botoes>


    <!-- CHAMADA PARA OUTRO COMPONENTE -->
        <app-gpq-exibicao
            (usuarioValidouChange)="onUsuarioValidouChange($event)"
            [usuarioValidou]="usuarioValidou"
            [gpq-id]="gpqId"
            [role]="role">
        </app-gpq-exibicao>
    </div>
}
<!-- Diálogo para Reprovação -->
<p-dialog
    header="Você deseja reprovar esta GPQ?"
    [modal]="true"
    [(visible)]="visible"
    [style]="{ width: '25rem' }">

    <span class="p-text-secondary block mb-5">
        Informe o motivo da reprovação para que ele seja corrigido.
    </span>

    <div class="flex align-items-center gap-3 mb-3">
        <label for="descricao" class="font-semibold">Motivo/Descrição da reprovação</label>
    </div>

    <div style="margin: 15px">
        <textarea
            id="descricao"
            rows="5"
            cols="30"
            pInputTextarea
            [(ngModel)]="value"
            placeholder="Digite o motivo da reprovação...">
        </textarea>
    </div>

    <div class="flex justify-content-end gap-2">
        <p-button
            label="Cancelar"
            severity="secondary"
            (onClick)="visible = false">
        </p-button>

        <p-button
            label="Confirmar"
            (onClick)="confirmarReprovacao()">
        </p-button>
    </div>
</p-dialog>

<!-- Diálogo para Alterar Status -->
<p-dialog header="Alteração de Status" [modal]="true" [(visible)]="visibleDialogStatus" [style]="{ width: '40rem' }">
    <div class="flex flex-column justify-content-center mb-3">
        <span class="p-text-primary block mb-3 large-text">
           Deseja realmente alterar o Status da GPQ?
           O status será alterado para <strong>"{{statusGpq == 'Ferias'? 'Criado': 'Não houve GPQ'}}"</strong>
        </span>
    </div>

    <div class="flex justify-content-end gap-2">
        <p-button label="Cancelar" severity="secondary" (onClick)="showHideAlterarStatusDialog()" />
        <p-button label="Confirmar" (onClick)="alterarStatusGpq()"/>
    </div>
</p-dialog>
