<ul [ngClass]="type">
    @if(type === 'side-bar-menu'){
        <li *ngFor="let item of buttons" (click)="item.action? item.action():null">
            <a
                *ngIf="type === 'side-bar-menu'"
                [routerLink]="item.route" 
                class="menu-config-item hover:text-primary-500 transition-duration-200"
                
            >
                @if(item.src){
                    <img class="profile-img menu-img" [src]="item.src" [alt]="item.label">
                }
                @else {
                    <i class="pi menu-icon menu-img" [ngClass]="item.icon"></i>
                }
                    <span>{{item.label}}</span>
            </a>
        </li>
    }
    @if(type === 'top-bar-menu'){ 
        <li class="topbar-item">
            <a
                pStyleClass="@next"
                enterFromClass="ng-hidden"
                enterActiveClass="px-scalein"
                leaveToClass="ng-hidden"
                leaveActiveClass="px-fadeout"
                [hideOnOutsideClick]="true"
                pRipple
                class="cursor-pointer"
            >
                <img
                    class="profile-img"
                    [src]="urlImage"
                    alt="Profile"
                />
            </a>
            <ul
                [class]="'hiden-top-bar-menu active-topbar-menu p-4 w-15rem  z-5 ng-hidden'"
            >
                <li *ngFor="let item of buttons" (click)="item.action? item.action():null">
                    <a
                        [routerLink]="item.route" 
                        class="hover:text-primary-500 transition-duration-200 flex align-items-center hover:text-primary-500 transition-duration-200"
                        pStyleClass="@grandparent"
                        enterFromClass="ng-hidden"
                        enterActiveClass="px-scalein"
                        leaveToClass="ng-hidden"
                        leaveActiveClass="px-fadeout"
                    >
                        <i class="pi menu-icon menu-img" [ngClass]="item.icon"></i>
                        <span>{{item.label}}</span>
                    </a>
                </li>
            </ul>

        </li>
    }
</ul>