import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsuarioResponse } from '../../models/usuarios/UsuarioResponse';
import { UserService } from '../user-service/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private apiUrl = environment.API_URL + 'auth/login'

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  private setHeadersConfiguration() {
    return new HttpHeaders({'secret': environment.API_SECRET});
  }

  login(email: string, password: string): Observable<any> {
    const data = {email, password}
    const headers = this.setHeadersConfiguration();
    return this.http.post(this.apiUrl, data, {
      headers: headers
    }).pipe(
      tap((value: any) => {
        sessionStorage.setItem("token", value.token)
        sessionStorage.setItem("role", value.role)
    }),
    switchMap((value: any) =>
      this.userService.buscarDados().pipe(
        tap((user: UsuarioResponse) => {
            this.userService.setUsuarioAutenticado(user)
        }),
        map(() => value)
      )
    ))
  }
}
