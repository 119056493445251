import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsuarioResponse } from '../../models/usuarios/UsuarioResponse';

@Injectable({
    providedIn: 'root',
})
export class PerfilService {
    private readonly apiUrl = environment.API_URL + 'users';
    private readonly apiUrlAuth = environment.API_URL + 'auth/alterar-senha';

    private readonly emptyProfilePhotoUrl =
        '/assets/images/fesf/empty-profile-photo.png';

    private imageUrlSubject = new BehaviorSubject<string>(
        this.emptyProfilePhotoUrl
    );
    imageUrl$ = this.imageUrlSubject.asObservable();

    constructor(private http: HttpClient) {
        this.fetchPhoto();
    }

    private fetchPhoto() {
        let headers = this.setHeadersConfiguration();
        this.http
            .get<Blob>(this.apiUrl + '/perfil/foto', {
                headers: headers,
                responseType: 'blob' as 'json',
            })
            .pipe(
                tap((response) => {
                    let url = this.emptyProfilePhotoUrl;
                    if (response != null) {
                        url = URL.createObjectURL(response);
                    }
                    this.imageUrlSubject.next(url);
                })
            )
            .subscribe();
    }

    private setHeadersConfiguration() {
        let token = sessionStorage.getItem('token');
        return new HttpHeaders({
            secret: environment.API_SECRET,
            Authorization: `Bearer ${token}`,
        });
    }

    getFotoPerfil() {
        this.fetchPhoto();
    }

    anexarFotoPerfil(file: File) {
        const formData = new FormData();
        formData.append('file', file);

        let headers = this.setHeadersConfiguration();

        return this.http
            .post<any>(this.apiUrl + '/perfil/foto', formData, {
                headers: headers,
            })
            .pipe(
                tap(() => {
                    this.getFotoPerfil();
                })
            );
    }

    updateImageUrl(newUrl: string) {
        this.imageUrlSubject.next(newUrl);
    }

    removerFotoPerfil() {
        let headers = this.setHeadersConfiguration();

        return this.http
            .delete<any>(this.apiUrl + '/perfil/foto', { headers: headers })
            .pipe(
                tap(() => {
                    this.updateImageUrl(this.emptyProfilePhotoUrl);
                })
            );
    }

    getInfoPerfil(email: string) {
        let headers = this.setHeadersConfiguration();
        let params = new HttpParams().set('email', email);
        return this.http.get<UsuarioResponse>(this.apiUrl + '/myInfo', {
            headers: headers,
            params: params,
        });
    }

    getFotoDeOutroPerfil(email: string): Observable<string> {
        let headers = this.setHeadersConfiguration();
        let params = new HttpParams().set('usuario', email);

        return this.http
            .get<Blob>(`${this.apiUrl}/perfil/foto`, {
                headers: headers,
                params: params,
                responseType: 'blob' as 'json',
            })
            .pipe(
                map((response) => {
                    if (response) {
                        const url = URL.createObjectURL(response);
                        return url;
                    } else {
                        return this.emptyProfilePhotoUrl;
                    }
                }),
                catchError((error) => {
                    console.error(error);
                    return of(this.emptyProfilePhotoUrl);
                })
            );
    }

    private setHeadersConfigurationWithoutToken() {
        return new HttpHeaders({
            secret: environment.API_SECRET,
        });
    }

    solicitarCodigo(email: string) {
        let headers = this.setHeadersConfigurationWithoutToken();
        return this.http.post(this.apiUrlAuth + '/gerar-codigo', email, {
            headers: headers,
        });
    }

    verificarCodigo(codigo: string, email: string) {
        let headers = this.setHeadersConfigurationWithoutToken();
        let body = {
            email: email,
            codigo: codigo,
        };
        return this.http.post(this.apiUrlAuth + '/validar-codigo', body, {
            headers: headers,
        });
    }

    alterarSenha(novaSenha: string, email: string) {
        let headers = this.setHeadersConfigurationWithoutToken();
        let body = {
            email: email,
            novaSenha: novaSenha,
        };

        return this.http.post(this.apiUrlAuth + '/nova-senha', body, {
            headers: headers,
        });
    }
}
