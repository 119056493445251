<p-card>
    @if(this.loading){
        <div class="flex col p-8 justify-content-center">
            <p-progressSpinner ariaLabel="loading"/>
        </div>
    }
    @else {
        <div class="flex flex-row align-items-center justify-content-between gap-3">
            <p style="font-size: 2rem; font-weight: bold;">Respostas</p>
            @if(statusGpq == "PendenteMunicipio"){
                <p-toolbar class="flex flex-column p-3">
                    <div class="flex flex-column">
                        <div>Quantidade de usuários municipais deste SPNH: <p-badge [value]="totalValidadoresSMS" class="pl-2"/></div>
                        <div>Validações municipais restantes: <p-badge [value]="(totalValidadoresSMS! - quantidadeValidada!)" class="pl-2"/></div>
                    </div>
                </p-toolbar>
            }
        </div>
        <p-accordion [multiple]="true">
            <p-accordionTab [header]="action.title" *ngFor="let action of actions">
                @for(question of action.questions; track question.id){
                    <p class="m-0" style="padding-left: 15px;">
                        {{question.title}}: <p-badge [value]="question.answer" class="pl-2"/>
                        <p-divider />
                    </p>
                }
            </p-accordionTab>

        </p-accordion>
        <p-divider class="p-5"/>
        <p style="font-size: 2rem; font-weight: bold;">Formulários</p>
        <app-painel-formularios [gpqDocumentosStatus]="gpqDocumentosStatus"></app-painel-formularios>
    }
</p-card>
