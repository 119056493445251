import {Component, OnInit} from '@angular/core';
import { LayoutService } from 'src/app/core/service/app.layout.service';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit {

    currentDate: string = '';

    constructor(public layoutService: LayoutService) {}

    ngOnInit(): void {
        this.currentDate = this.getCurrentDate();
    }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    getCurrentDate(): string {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Meses são de 0-11, então adicione 1
        const day = String(now.getDate()).padStart(2, '0');

        return `${day}/${month}/${year}`;
    }

}
