<p-toast />
<div class="card">
    <app-header-com-botoes
    [title]="'Minhas GPQs'"
    [buttons]="buttons"
    [showMainButtons]="{history: gpqSelecionado!==null, return: false}"
    >
    </app-header-com-botoes>
<div class="card">
    <p-table
    [value]="listaGpq"
    selectionMode="single"
    [(selection)]="gpqSelecionado"
    sortField="data"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-gridlines"
    >
    <ng-template pTemplate="caption">
        <div class="flex" style="gap: 10px">
            <p-splitButton
                label="Nova GPQ"
                icon="pi pi-plus"
                (onClick)="criarNovoGpq()"
                [model]="splitButtonAcoes"
                [disabled]="CriandoGPQ()"/>

            <p-calendar
                [showIcon]="true"
                [iconDisplay]="'input'"
                [showClear]="true"
                (onClear)="clearSelection()"
                id="data"
                inputId="data"
                formControlName="data"
                dateFormat="mm/yy"
                appendTo="body"
                view="month"
                (onSelect)="filtrarPeloMes($event)"
                placeholder="Data da GPQ"
            />
        </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th style="width: 50%; text-align: center">Mês/Ano referente</th>
            <th style="width: 50%; text-align: center">Status</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-gpq>
        <tr>

            @if (gpq.statusDoDocumento != 'Bloqueado') {
            <td>
                <p-tableRadioButton [value]="gpq" (click)="selecionarGpq(gpq)" />
            </td>

            <td style="cursor: pointer" (click)="navigateToFormularioGpq(gpq)">
                {{ dataFormat.formatDataToStringMesAno(gpq.dataReferente) }}
            </td>


                <td style="cursor: pointer" (click)="navigateToFormularioGpq(gpq)">
                    <p-tag
                        [severity]="getSeverityStatus(gpq.statusDoDocumento)"
                        [value]="getStatusGpq(gpq.statusDoDocumento)"
                    />
                </td>
            }@else {
                <td></td>

                <td>
                    {{ dataFormat.formatDataToStringMesAno(gpq.dataReferente) }}
                </td>

                <td>
                    <p-tag
                        [severity]="getSeverityStatus(gpq.statusDoDocumento)"
                        [value]="getStatusGpq(gpq.statusDoDocumento)"
                    />
                </td>
            }


        </tr>
    </ng-template>
</p-table>
</div>

</div>
<p-dialog header="Confirmação" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">

    <div class="flex flex-column justify-content-center mb-3">
        <span class="p-text-secondary block mb-3">Digite o texto mostrado abaixo para confirmar:</span>
      <span class="expected-text">"{{ expectedText }}"</span>
    </div>

    <div class="flex flex-column mb-3">
      <input pInputText #textInput id="inputText" autocomplete="off" placeholder="Digite aqui"  />
    </div>

    <div class="flex justify-content-end gap-2">
        <p-button label="Confirmar" (onClick)="verifyText(textInput.value)" />
        <p-button label="Cancelar" severity="secondary" (onClick)="visible = false" />
    </div>
</p-dialog>
