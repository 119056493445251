import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Anexo } from 'src/app/application/models/Anexo';
import { GpqAction } from 'src/app/application/models/GpqAction';
import { GpqDocumentosStatus } from 'src/app/application/models/GpqDocumentosStatus';
import { Question } from 'src/app/application/models/Question';
import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { PartoHumanizadoMessageService } from 'src/app/core/service/parto-humanizado-message.service';

@Component({
  selector: 'app-gpq-exibicao',
  templateUrl: './gpq-exibicao.component.html',
  styleUrl: './gpq-exibicao.component.scss'
})
export class GpqExibicaoComponent implements OnInit{

  actions: any = []
  @Input({alias: 'gpq-id', required: true})
  gpqId!: number;

  @Input({alias: 'role', required: true})
  role!: string;
  anexos: Anexo[] = []
  gpqDocumentosStatus!: GpqDocumentosStatus;
  loading = true;


  @Input({required: true})
  usuarioValidou!: boolean | null;
  @Output()
  usuarioValidouChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  totalValidadoresSMS!: number | null;
  quantidadeValidada!: number | null;
  statusGpq!: string;

  constructor(
    private gpqService: GpqService,
    private messageService: PartoHumanizadoMessageService,
    private location: Location
  ){}

  ngOnInit(): void {
    this.gpqService.getGpq((gpq) => {
      if(!gpq) {
        this.messageService.erroAoExibirGpq()
        return
      }

      this.gpqId = gpq.numeroDoDocumento
      this.statusGpq = gpq.statusDoDocumento

      if(this.statusGpq == StatusGpq.PendenteMunicipio)
        this.gpqService.votacaoMunicipal(gpq.numeroDoDocumento).subscribe({
          next: (response: any) => {
            this.totalValidadoresSMS = response.totalValidadoresSMS;
            this.quantidadeValidada = response.quantidadeValidada;
            this.usuarioValidou = response.usuarioValidou;
            this.usuarioValidouChange.emit(this.usuarioValidou!);
          },
          error: () => {
            this.messageService.erroAoExibirGpq()
          }
        })

      if(this.statusGpq == StatusGpq.PendenteFesf || this.statusGpq == StatusGpq.PendenteSesab) {
        this.usuarioValidou = false
        this.usuarioValidouChange.emit(this.usuarioValidou!);
      }

      this.gpqService.listarGpqDetalhado(this.gpqId).subscribe({
        next: (response) => {
          this.actions = response.respostas
          this.anexos = response.anexos
          this.actions.forEach(
            (action: GpqAction) => {
              const controls: any = {}
              action.questions.forEach(
                (question: Question) => {
                  controls[question.id] = [question.answer]
                }
              )
            }
          )
          this.gpqDocumentosStatus = this.mapGpqToGpqDocumentosStatus(response);
          this.loading = false;
        },
        error: () => {
          this.messageService.erroAoExibirGpq()
        }
      })
    }).unsubscribe()
  }

  private mapGpqToGpqDocumentosStatus(gpq: any): GpqDocumentosStatus {
    const statusDocumentos = gpq.statusDocumentos;
    return {
        ausenciaDeVinculacao: statusDocumentos.ausenciaDeVinculacao,
        ausenciaDePreNatal: statusDocumentos.ausenciaDePreNatal,
        ausenciaDePartos: statusDocumentos.ausenciaDePartos,
        ausenciaDeEncontroGestantes: statusDocumentos.ausenciaDeEncontroGestantes,
        ausenciaDeEducacaoPermanente: statusDocumentos.ausenciaDeEducacaoPermanente,
        ausenciaDeReuniaoMonitoramento: statusDocumentos.ausenciaDeReuniaoMonitoramento,
        ausenciaDeGestacaoDeRisco: statusDocumentos.ausenciaDeGestacaoDeRisco,
        ausenciaDePartoDeRisco: statusDocumentos.ausenciaDePartoDeRisco,

        temAssinaturaPreNatal: statusDocumentos.temAssinaturaPreNatal,
        temAssinaturaVinculacao: statusDocumentos.temAssinaturaVinculacao,
        temAssinaturaEncontroGestantes: statusDocumentos.temAssinaturaEncontroGestantes,
        temAssinaturaEducacaoPermanente: statusDocumentos.temAssinaturaEducacaoPermanente,
        temAssinaturaReuniaoMonitoramento: statusDocumentos.temAssinaturaReuniaoMonitoramento,
    };
  }
}
