import { Component, OnInit } from '@angular/core';
import { UsefulSectorInformation } from 'src/app/core/models/useful-sector-information';
import { Clipboard } from '@angular/cdk/clipboard';
import { SpnhService } from 'src/app/application/services/spnh-service/spnh.service';
import { Spnh } from 'src/app/application/models/Spnh';

@Component({
    selector: 'app-useful-sector-information',
    templateUrl: './useful-sector-information.component.html',
})
export class UsefulSectorInformationComponent implements OnInit {

    sectorsInformation: UsefulSectorInformation[] = [];
    spnh: Spnh | null = null;
    showForm: boolean = false;

    constructor(private clipboard: Clipboard, private spnhService: SpnhService ) { }
    ngOnInit(): void {
        this.sectorsInformation = [
            {
                title: 'Fundação Estatal Saúde da Familia (FESF)',
                phone1: '(71) 3417-3500',
                phone2: '(71) 3417-3506',
                address: '',
                website: 'https://www.fesfsus.ba.gov.br/'
            },
            {
                title: 'Secretaria da Saúde do Estado da Bahia (SESAB)',
                phone1: '(71) 3115-4384.',
                phone2: '',
                address: '',
                website: 'https://www.saude.ba.gov.br/'
            },
            {
                title: 'Ministério da Saúde',
                phone1: 'Serviço Disque Sáude: 136',
                phone2: '',
                address: '',
                website: 'https://www.gov.br/saude/pt-br'
            },
        ];

        this.getSpnhInfo()

    }

    private getSpnhInfo(){
        let role = sessionStorage.getItem('role')
        let user = JSON.parse(sessionStorage.getItem('userAuth')!);

        if(role == 'Enfermeira' || role == 'Sms'){
            this.spnhService.listarPorId(user.spnh.numeroDoSpnh).subscribe({
                next: (response) => {
                    this.spnh = response
                }
            })
        }
    }

    addSectorInformation(newSector: UsefulSectorInformation) {
        this.sectorsInformation.push(newSector);
        this.showForm = false;
    }

    showAddForm() {
        this.showForm = true;
    }

    copyToClipboard(sector: UsefulSectorInformation) {
        let text = sector.title ? sector.title + '\n' : '';

        if (sector.phone1) {
            text += 'Telefone 1: ' + sector.phone1 + '\n';
        }
        if (sector.phone2) {
            text += 'Telefone 2: ' + sector.phone2 + '\n';
        }
        if (sector.address) {
            text += 'Endereço: ' + sector.address;
        }

        this.clipboard.copy(text);
    }

}
