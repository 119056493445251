import { CanActivateFn, Router } from '@angular/router';
import { UserRole } from '../../models/UserRoles';
import { inject } from '@angular/core';

export const userRoleGuard: CanActivateFn = (route, state) => {

    const rolesPermitidas = route.data['roles'] as Array<UserRole>;
    const userRole = sessionStorage.getItem('role')

    const router = inject(Router);

    if(!userRole){
        router.navigate([''])
        return false;
    }

    if(!rolesPermitidas.includes(userRole as UserRole)){
        router.navigate(['access-denied'])
        return false;
    }

    return true;
};

