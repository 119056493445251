import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EquipeSpnh } from '../../models/EquipeSpnh';
import { Enfermeira } from '../../models/usuarios/Enfermeira';
import { UsuarioRequest } from '../../models/usuarios/UsuarioRequest';
import { UsuarioUpdate } from '../../models/usuarios/UsuarioRequestUpdate';
import { UsuarioUpdateProfile } from '../../models/usuarios/UsuarioRequestUpdateProfile';
import { UsuarioResponse } from '../../models/usuarios/UsuarioResponse';
import { EnfermeiraService } from '../enfermeira-service/enfermeira.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly apiUrl = environment.API_URL + 'users'
  private readonly userUrl = environment.API_URL + 'auth/cadastrar'

  setUsuarioAutenticado(user: UsuarioResponse) {
    sessionStorage.setItem('userAuth', JSON.stringify(user))
  }

  getUsuarioAutenticado(): UsuarioResponse {
    return JSON.parse(sessionStorage.getItem('userAuth')!);
  }

  setUsuario(usuario: UsuarioResponse) {
		sessionStorage.setItem('usuario', JSON.stringify(usuario))
	}

	getUsuario() {
		const usuario = sessionStorage.getItem('usuario')!
		return JSON.parse(usuario)
	}

  clearUsuarioFromStorage() {
		sessionStorage.removeItem('usuario')
	}

  constructor(private http: HttpClient,
    private enfermeiraService: EnfermeiraService
  ) {}

  private setHeadersConfiguration() {
    let token = sessionStorage.getItem('token')
    return new HttpHeaders({
      'secret': environment.API_SECRET,
      'Authorization': `Bearer ${token}`
    })
  }

  listarUsuarios(): Observable<UsuarioResponse[]> {
    return this.http.get<UsuarioResponse[]>(this.apiUrl, {
      headers: this.setHeadersConfiguration()
    })
  }

  listarUsuariosEquipeMunicipio(codigoMunicipio: number): Observable<EquipeSpnh> {
    return this.http.get<EquipeSpnh>(this.apiUrl + "/equipe/municipio/" + codigoMunicipio, {
      headers: this.setHeadersConfiguration()
    })
  }

  cadastrar(data: UsuarioRequest | Enfermeira): Observable<any> {
    if('coren' in data) {
      return this.enfermeiraService.cadastrar(data)
    }
    return this.http.post(this.userUrl, data, {
      headers: this.setHeadersConfiguration()
    })
  }

  buscarDados(): Observable<any> {
    return this.http.get(this.apiUrl + '/myInfo', {
      headers: this.setHeadersConfiguration()
    })
  }

  listarUsuariosMunicipio(codigoMunicipio: number): Observable<UsuarioResponse[]>{
    let headers = this.setHeadersConfiguration()
    return this.http.get<UsuarioResponse[]>(this.apiUrl + '/municipio/' + codigoMunicipio, {
      headers: headers
    })
  }

  buscarUsuarioAutenticado(): Observable<any>{
    let headers = this.setHeadersConfiguration()
    return this.http.get<any>(this.apiUrl + '/myInfo', {
      headers: headers
    })
  }

  atualizar(id: string, data: UsuarioUpdate | UsuarioUpdateProfile): Observable<UsuarioResponse> {
    return this.http.put<UsuarioResponse>(this.apiUrl + `/atualizar/${id}`, data, {
        headers: this.setHeadersConfiguration(),
    })
  }

  remover(id: string): Observable<void> {
    return this.http.delete<void>(this.apiUrl + `/${id}`, {
        headers: this.setHeadersConfiguration(),
    })
  }

  usuarioPossuiPendencias(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/pendencias/${id}`, {
      headers: this.setHeadersConfiguration()
    })
  }
}
