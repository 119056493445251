import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Municipio } from 'src/app/application/models/Municipio';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent {


  count: any;
  listaMunicipiosPreDefinida: Municipio[] = [
    {
      nome: 'Souto Soares',
      codigo: 2930808
    },
    {
      nome: 'Pau Brasil',
      codigo: 2923902
    },
    {
      nome: 'Cordeiros',
      codigo: 2909000
    },
    {
      nome: 'Jacaraci',
      codigo: 2917409
    },
    {
      nome: 'Barrocas',
      codigo: 2903276
    },
    {
      nome: 'Dom Basílio',
      codigo: 2910107
    },
    {
      nome: 'Conde',
      codigo: 2908606
    },
    {
      nome: 'Caturama',
      codigo: 2907558
    },
    {
      nome: 'Biritinga',
      codigo: 2903607
    },
    {
      nome: 'Buritirama',
      codigo: 2904753
    },
    {
      nome: 'Itaeté',
      codigo: 2915007
    },
    {
      nome: 'Rio de Contas',
      codigo: 2926707
    },
    {
      nome: 'Elísio Medrado',
      codigo: 2910305
    },
    {
      nome: 'Igrapiúna',
      codigo: 2913457
    },
  ]
  
  constructor(){

  }
}
