import { Location } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Gpq } from 'src/app/application/models/Gpq';
import { HistoricoGpq } from 'src/app/application/models/HistoricoGpt';
import { UsuarioResponse } from 'src/app/application/models/usuarios/UsuarioResponse';
import { VisualizacaoMensagens } from 'src/app/application/models/VisualizacoesMensagens';
import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { HistoricoService } from 'src/app/application/services/historico-service/historico.service';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { DataFormatService } from 'src/app/core/service/format-service/data-format/data-format.service';
import { TextFormatService } from 'src/app/core/service/format-service/text-format/text-format.service';
import { PartoHumanizadoMessageService } from 'src/app/core/service/parto-humanizado-message.service';

@Component({
  selector: 'app-historico-gpq',
  templateUrl: './historico-gpq.component.html',
  styleUrl: './historico-gpq.component.scss'
})
export class HistoricoGpqComponent implements OnInit, AfterViewChecked{

  historicoGpq: HistoricoGpq[] = [];
  gpq!: Gpq;
  usuario: UsuarioResponse | null = null;

  mensagem: string = '';
  mensagens: HistoricoGpq[] = [];
  visualizacoes: VisualizacaoMensagens[] | null = null;
  buscandoMensagens: boolean = false;
  @ViewChild('messagesSection') private scrollMensagens!: ElementRef;

  constructor(
    private historicoService: HistoricoService,
    private messageService: PartoHumanizadoMessageService,
    private location: Location,
    private gpqService: GpqService,
    public dataFormat: DataFormatService,
    public textFormat: TextFormatService,
  ){}

  ngAfterViewChecked(): void {
    this.scrollToButton();
  }

  ngOnInit(): void {

    this.gpqService.getGpq((gpq) => {
      if(!gpq){
        this.navigateLastPage()
        this.messageService.showErrorMessage('Erro', 'Gpq não encontrado, tente novamente')
        return;
      }

      if(gpq.statusDoDocumento == 'Ferias'){
        this.navigateLastPage()
        return;
      }

      this.gpq = gpq;
      this.historicoService.historicoGpq(this.gpq.numeroDoDocumento).subscribe({
        next: (response) => {
          this.historicoGpq = response.map(item => {
            item.localDateTime = new Date(item.localDateTime);
            return item;
          })
        },
        error: () => this.historicoGpq = []
      })

      this.atualizarMensagens();

    }).unsubscribe()

    this.usuario = this.historicoService.getUsuario();
  }

  enviarMensagem(){
    if(this.mensagem.length === 0 || this.mensagem.length >= 2048){
      return;
    }

    this.gpqService.enviarMensagem(this.gpq.numeroDoDocumento, this.mensagem).subscribe({
      next: (response) => {
        this.atualizarMensagens();
        this.mensagem = '';
      },
      error: () => this.messageService.showErrorMessage('Erro', 'Não foi possível enviar mensagem')
    })
  }

  atualizarMensagens(){
    this.buscandoMensagens = true;
    this.historicoService.mensagensGpq(this.gpq.numeroDoDocumento).subscribe({
      next: (response) => {
        this.mensagens = response.map(item => {
            item.localDateTime = new Date(item.localDateTime);
            return item;
          });
        this.scrollToButton();
        this.buscandoMensagens = false;
      },
      error: () => {
        this.mensagens = []
        this.messageService.showErrorMessage("Error", "Erro ao recuperar as mensagens");
      }
    })
  }

  atualizarVisualizacoes(message: HistoricoGpq){
    this.historicoService.visualizacoesMensagem(this.gpq.numeroDoDocumento).subscribe({
      next: (response) => this.visualizacoes = response.filter(msg => msg.usuario.uuid !== message.identificador),
      error: () => this.messageService.showErrorMessage('Erro', 'Não foi possível recuperar as visualizações')
    })
  }

  mostrarVisualizacoes(event: Event, overlayPanel: OverlayPanel, message: HistoricoGpq) {
    this.atualizarVisualizacoes(message);
    overlayPanel.toggle(event);
  }

  passouUmDia(data: Date){
    const dataDaMensagem = new Date(data);
    const timeDiff = Math.abs(new Date().getTime() - dataDaMensagem.getTime());
    const umDiaEmMilissegundos = 1000 * 3600 * 24;

    return timeDiff >= umDiaEmMilissegundos;
  }

  scrollToButton(){
    try {
      this.scrollMensagens.nativeElement.scrollTo({
        top: this.scrollMensagens.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
    } catch (err) {

    }
  }

getIcon(voto: string){
  switch(voto){
    case 'Rejeição':
      return 'pi pi-times-circle';
    case 'Aceitação':
      return 'pi pi-check-circle';
    case 'Submissão':
      return 'pi pi-send'
    default:
      return ''
  }
}

getColorIcon(voto: string){
    switch(voto){
      case 'Rejeição':
        return '#DE3163';
      case 'Aceitação':
        return '#9FE2BF';
      case 'Submissão':
        return '#6495ED'
      default:
        return ''
    }
  }

  navigateLastPage(){
    this.location.back()
  }

}
