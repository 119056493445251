<div class="layout-container" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <app-config></app-config>
    <div class="layout-content-wrapper">
        <div class="layout-content">
            <app-breadcrumb></app-breadcrumb>
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profilemenu></app-profilemenu>

    <div class="layout-mask"></div>
</div>
<!-- LOGO DA FESF NO RODAPÉ -->
<div class="layout-footer" style="width: 100%">
    <div class="container" style="text-align: center">
        <a href="https://www.fesfsus.ba.gov.br" target="_blank">
            <img
                src="assets/images/fesf/LogoFESFSUS.png"
                alt="Logo"
                height="25"
                class="mr-2"
            />
        </a>
        <div style="text-align: center; padding-top: 10px">
            <h6 style="color: black">
                <span>{{ copyRigth }}</span>
                <a href="https://www.fesfsus.ba.gov.br/" target="_blank"
                    ><span style="text-decoration: none; color: black">{{
                        fesf
                    }}</span></a
                >
                <a
                    href="https://gsort.ifba.edu.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span style="text-decoration: none; color: black">{{
                        gsort
                    }}</span></a
                >
                <a
                    href="https://portal.ifba.edu.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><span style="text-decoration: none; color: black">{{
                        ifba
                    }}</span></a
                >
            </h6>
        </div>
    </div>
</div>
