<div class="top-bar-section">
    <div class="layout-topbar bg-white" [ngClass]="{ scrolled: isScrolled }">
        <div class="topbar-start side-menu-button">
            <button
                #menubutton
                type="button"
                class="topbar-menubutton p-link p-trigger transition-duration-300"
                (click)="onMenuButtonClick()"
            >
                <i class="pi pi-bars"></i>
            </button>
        </div>
        <div class="topbar-end">
            <ul class="topbar-menu">
                <li>
                    <app-gpq-selecionado />
                </li>

                <config-menu type="top-bar-menu"></config-menu> 
            </ul>
        </div>
    </div>
    <div class="layout-topbar-menu-section">
        <app-sidebar></app-sidebar>
    </div>
</div>
