<div class="card mt-3">
<app-header-com-botoes
    [title]="'Histórico de Ações'"
    [buttons]="[]">
</app-header-com-botoes>
</div>

@if(historicoGpq.length == 0){
<div class="card mt-3" style="text-align: center;">
    <h3>Esta GPQ não tem nenhum histórico</h3>
</div>
} @else {
<div class="card mt-3">
    <p-timeline [value]="historicoGpq" align="alternate" styleClass="customized-timeline">
        <ng-template pTemplate="marker" let-event>
            <span
                class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
                [style]="{ 'background-color': getColorIcon(event.voto) }">
                <i [class]="getIcon(event.voto)"></i>
            </span>
        </ng-template>
        <ng-template pTemplate="content" let-event>
            <p-card [header]="textFormat.formatStatusVoto(event.voto)" subheader="{{event.localDateTime | date: 'dd/MM/yyyy HH:mm:ss'}} - {{event.nomeDoUsuario}} ({{event.role}})" styleClass="Submissao">
              <ng-template pTemplate="body">
                @if(event.descricao != null){
                  <p>
                    {{event.descricao}}
                </p>
                }
              </ng-template>
            </p-card>
        </ng-template>
    </p-timeline>
</div>
}
<section class="card chat-section">
    <div #messagesSection class="messages-section scrollbarStyle">
        <div *ngIf="mensagens.length === 0 && !buscandoMensagens" class="empty-message-history">
            <span class="empty-message-history-icon pi pi-comments"></span>
            <span class="empty-message-history-text">Histórico de mensagens vazio</span>
        </div>
        <p-progressSpinner *ngIf="buscandoMensagens" class="searching-messages"></p-progressSpinner>
        <div
            *ngFor="let message of mensagens"
            (click)="mostrarVisualizacoes($event, op, message)"
            [ngClass]="{'sent-by-others': message.identificador !== usuario?.uuid, 'sent-by-me': message.identificador === usuario?.uuid}"
            class="message-card">
            <div class="sent-by">
                @if(message.identificador !== usuario?.uuid){
                    <span>{{message.nomeDoUsuario}} ({{message.role}})</span>
                    <span  *ngIf="passouUmDia(message.localDateTime)">{{dataFormat.formatDataToStringDiaMesAno(message.localDateTime)}}</span>
                }
                @else{
                    <span  *ngIf="passouUmDia(message.localDateTime)">{{dataFormat.formatDataToStringDiaMesAno(message.localDateTime)}}</span>
                    <span>Eu ({{message.nomeDoUsuario}})</span>
                }
            </div>
            <div class="message-text">
                <p>{{message.descricao}}</p>
            </div>
            <span class="message-date-time">
                <p style="text-align: right;">{{dataFormat.formatTimeToHoraMinuto(message.localDateTime)}}</p>
            </span>
        </div>
        <p-overlayPanel #op>
            <div class="message-views">
                <div class="list-view saw-by">
                    <span class="message-views-title">Visualizado Por:</span>
                    <ul>
                        <ng-container *ngFor="let user of visualizacoes">
                            <li *ngIf="user.visualizado">
                                {{user.usuario.dadosCadastrais.nome}} ({{user.usuario.permissao}})
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </p-overlayPanel>
    </div>

    <div *ngIf="this.gpq.statusDoDocumento !== 'Aprovado'" class="write-message-section">
        <div class="text-input-div">
            <textarea
                class="text-input scrollbarStyle"
                rows="5"
                cols="30"
                pInputTextarea
                maxlength="2048"
                [(ngModel)]="mensagem">
            </textarea>
            <span class="message-word-count">{{mensagem.length}} / 2048</span>
        </div>
        <div class="send-message-div">
            <p-button
                [rounded]="true"
                [text]="true"
                [raised]="true"
                class="p-button-rounded"
                (onClick)="enviarMensagem()">
                <ng-template pTemplate="icon">
                    <img src="../../../../../assets/images/chat/send_message_icon.svg" alt="Enviar"/>
                </ng-template>
            </p-button>
            <p-button
                [rounded]="true"
                [text]="true"
                severity="secondary"
                icon="pi pi-refresh"
                class="p-button-rounded"
                pTooltip="Atualizar Mensagens" tooltipPosition="bottom"
                (onClick)="atualizarMensagens()"
                >
            </p-button>
        </div>
    </div>
</section>
