import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class PartoHumanizadoMessageService {
    constructor(
        private messageService: MessageService,
        private location: Location
    ) {}

    public showSuccessMessage(message: string, detail?: string) {
        this.messageService.add({
            severity: 'success',
            summary: message,
            detail: detail,
        });
    }

    public showErrorMessage(message: string, detail?: string) {
        this.messageService.add({
            severity: 'error',
            summary: message,
            detail: detail,
        });
    }

    public showInfoMessage(message: string, detail?: string) {
        this.messageService.add({
            severity: 'info',
            summary: message,
            detail: detail,
        });
    }

    public showWarnMessage(message: string, detail?: string) {
        this.messageService.add({
            severity: 'warning',
            summary: message,
            detail: detail,
        });
    }

    public showSecondaryMessage(message: string, detail?: string) {
        this.messageService.add({
            severity: 'secondary',
            summary: message,
            detail: detail,
        });
    }

    public showContrastMessage(message: string, detail?: string) {
        this.messageService.add({
            severity: 'contrast',
            summary: message,
            detail: detail,
        });
    }

    //   A PARTIR DAQUI MENSAGENS CRIADAS PARA SEREM REUTILIZADAS NA APLICAÇÃO

    public gpqNãoSelecionadoMessage() {
        this.showErrorMessage('Erro', 'Selecione uma GPQ primeiro.');
    }

    public anexoEnviadoSucessoMessage() {
        this.showSuccessMessage('Sucesso', 'Arquivo anexado com sucesso!');
    }

    public anexoErroEnvioMessage() {
        this.showErrorMessage('Erro', 'Erro ao enviar anexo, tente novamente.');
    }

    public anexoBaixadoSucessoMessage() {
        this.showSuccessMessage('Sucesso', 'Arquivo baixado com sucesso!');
    }

    public anexoBaixadoErroMessage() {
        this.showErrorMessage('Erro', 'Erro ao baixar anexo, tente novamente.');
    }

    public anexoRemovidoSucessoMessage() {
        this.showSuccessMessage('Sucesso', 'Axeno removido com sucesso!');
    }

    public anexoRemovidoErroMessage() {
        this.showErrorMessage('Erro', 'Erro ao remover anexo, tente novamente.');
    }

    public registroSalvoSucessoMessage(message: string, detail?: string) {
        this.showSuccessMessage(message, detail);
    }

    public registroEditadoSucessoMessage(message: string, detail?: string) {
        this.showSuccessMessage(message, detail);
    }

    public registroRemovidoSucessoMessage(message: string, detail?: string) {
        this.showSuccessMessage(message, detail);
    }

    public formularioInvalidoMessage() {
        this.showErrorMessage(
            'Formulário inválido',
            'Verifique o preenchimento dos campos.'
        );
    }

    public formularioNaoPreenchidoMessage() {
        this.showInfoMessage(
            'Formulário incompleto',
            'Preencha todos os campos.'
        );
    }

    public ausenciaSucessoMessage() {
        this.showSuccessMessage('Confirmação realizada com sucesso!');
        this.location.back()
    }

    confirmacaoSemComentarioMessage(tipoRegistro: string) {
        this.showErrorMessage('Justificativa necessária', 'Por favor, justifique a ausência de ' + tipoRegistro + ' nesta GPQ.')
    }

    public operacaoCanceladaMessage() {
        this.showInfoMessage(
            'Operação cancelada',
        );
    }

    public necessarioAihMessage() {
        this.showInfoMessage(
            'Falta anexo de AIH',
            'Partos realizados por enfermeira precisam de AIH.'
        );
    }

    public partoNaoTemAih() {
        this.showInfoMessage('Não há anexo de AIH para este parto.');
    }

    public erroAoListarRegistrosMessage(tipoRegistro: string) {
        this.showErrorMessage('Erro', 'Não foi possível resgatar os registros de ' + tipoRegistro + '.')
    }

    public erroAoExibirGpq() {
        this.messageService.clear()
        this.showErrorMessage('Erro', 'Ocorreu um erro ao coletar informações da GPQ.')
        this.location.back()
    }

    public erroDesconhecidoMessage(detail?: string) {
        this.showErrorMessage('Erro', detail ? detail! : 'Algo deu errado, tente novamente.');
    }
}
