<p-card header="Novo Setor">
    <form [formGroup]="sectorForm" (ngSubmit)="onSubmit()">
        <div class="p-field">
            <label class="mb-1" for="title">Nome do Setor</label>
            <input id="title" type="text" pInputText formControlName="title" />

            @if (sectorForm.controls['title'].invalid && sectorForm.controls['title'].touched) {
                <div class="p-error">
                    Nome do Setor é Obrigatório.
                </div>
            }

        </div>
        <div class="p-field">
            <label class="mb-1" for="phone1">Telefone 1</label>
            <p-inputMask
                id="phone1"
                mask="(99)-999999999"
                formControlName="phone1"
                placeholder="99-999999" />
            @if (sectorForm.controls['phone1'].invalid && sectorForm.controls['phone1'].touched) {
                <div class="p-error">
                    Insira u número válido.
                </div>
            }
        </div>
        <div class="p-field">
            <label class="mb-1" for="phone2">Telefone 2</label>
            <p-inputMask
                id="phone2"
                mask="(99)-999999999"
                formControlName="phone2"
                placeholder="99-999999" />
        </div>
        <div class="p-field">
            <label class="mb-1" for="address">Endereço</label>
            <input id="address" type="text" pInputText formControlName="address" />
            @if (sectorForm.controls['address'].invalid && sectorForm.controls['address'].touched) {
                <div class="p-error">
                    Endereço é Obrigatório
                </div>
            }
        </div>
        <div class="flex gap-2">
            <button type="submit" pButton label="Adicionar" [disabled]="sectorForm.invalid"></button>
            <button class="p-button-secondary m" pButton label="Cancelar" (click)="cancel()"></button>
        </div>
    </form>
</p-card>
