import { Component, OnDestroy, OnInit } from '@angular/core';
import { Gpq } from 'src/app/application/models/Gpq';
import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { SpnhService } from 'src/app/application/services/spnh-service/spnh.service';
import { RelatorioService } from 'src/app/application/services/relatorio-service/relatorio.service';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { PartoHumanizadoMessageService } from 'src/app/core/service/parto-humanizado-message.service';
import { UserRole } from 'src/app/core/models/UserRoles';

@Component({
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy {
    data: any;
    options: any;
    dataLine: any;
    optionsLine: any;
    visible: any;

    listaGpq: Gpq[] = [];
    listaGpqMunicipio: Gpq[] = [];

    date: Date;

    listaGpqBloqueados: Gpq[] = [];
    listaGpqEmPreenchimento: Gpq[] = [];
    listaGpqFesf: Gpq[] = [];
    listaGpqSesab: Gpq[] = [];
    listaGpqAprovados: Gpq[] = [];
    listaGpqFerias: Gpq[] = [];

    partosPorMunicipio: any[] = [];
    partosPorMes: any[] = [];
    userRole!: UserRole;

    constructor(
        private spnhService: SpnhService,
        private messageService: PartoHumanizadoMessageService,
        private relatorioService: RelatorioService,
        private gpqService: GpqService
    ) {
        this.date = new Date();

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue(
            '--text-color-secondary'
        );
        const surfaceBorder =
            documentStyle.getPropertyValue('--surface-border');

        this.options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500,
                        },
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                    },
                },
            },
        };

        this.optionsLine = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                    },
                },
            },
        };
    }

    ngOnInit() {
        const role = sessionStorage.getItem('role');
        this.userRole = role ? role as UserRole : UserRole.FESF;

        this.gpqService.listarGpqPorMes(this.date).subscribe({
            next: (response) => {
                this.listaGpq = response;
                this.filtrarListaGpq();
            },
            error: (response) => this.messageService.showErrorMessage('Erro', 'Ocorreu um erro ao carregar a página.'),
        });

        this.relatorioService.obterRelatorioDashBoard(new Date(), 'anual').subscribe({
            next: (response) => {
                this.processarDados(response);
            },
            error: (response) => this.messageService.showErrorMessage('Erro', 'Ocorreu um erro ao carregar a página.')
        });
    }

    ngOnDestroy(): void {}

    processarDados(response: any) {
        this.partosPorMunicipio = response.partosPorMunicipio;

        this.data = {
            labels: this.partosPorMunicipio.map((item) => item.municipio),
            datasets: [
                {
                    label: 'Partos por município',
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--graph-main-color'),
                    data: this.partosPorMunicipio.map((item) => item.quantidade)
                }
            ]
        };

        const partosPorMes = response.partosPorMes || [];
        const mesesDoAno = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        const partosMap = this.preencherMesesComZeros(mesesDoAno, partosPorMes);

        this.dataLine = {
            labels: mesesDoAno,
            datasets: [
                {
                    label: 'Nº de partos',
                    data: partosMap,
                    fill: false,
                    borderColor: getComputedStyle(document.documentElement).getPropertyValue('--graph-main-color'),
                    tension: 0.4
                }
            ]
        };
    }

    preencherMesesComZeros(mesesDoAno: string[], partosPorMes: any[]): number[] {
        const partosMap = Array(12).fill(0);

        partosPorMes.forEach((item) => {
            const [mes, ano] = item.mes.split('/');
            const indiceMes = parseInt(mes, 10) - 1;
            partosMap[indiceMes] = item.quantidadePartos;
        });

        return partosMap;
    }

    buscarGpqPorData() {
        this.gpqService.listarGpqPorMes(this.date).subscribe({
            next: (response) => {
                this.listaGpq = response;
                this.filtrarListaGpq();
            },
            error: (response) => this.messageService.showErrorMessage('Erro', 'Falha ao buscar Gpq.'),
        });
    }

    exibirFormularioSpnh() {
        this.visible = !this.visible;
    }

    filtrarListaGpq() {
        this.listaGpqAprovados = [];
        this.listaGpqEmPreenchimento = [];
        this.listaGpqFesf = [];
        this.listaGpqMunicipio = [];
        this.listaGpqSesab = [];
        this.listaGpqFerias = [];

        this.listaGpq.forEach((gpq: Gpq) => {
            switch (gpq.statusDoDocumento) {
                case StatusGpq.Aprovado:
                    this.listaGpqAprovados.push(gpq);
                    break;
                case StatusGpq.PendenteMunicipio:
                    this.listaGpqMunicipio.push(gpq);
                    break;
                case StatusGpq.PendenteFesf:
                    this.listaGpqFesf.push(gpq);
                    break;
                case StatusGpq.PendenteSesab:
                    this.listaGpqSesab.push(gpq);
                    break;
                case 'Ferias':
                    this.listaGpqFerias.push(gpq);
                    break;
                case StatusGpq.Bloqueado:
                    this.listaGpqBloqueados.push(gpq);
                    break;
                default:
                    this.listaGpqEmPreenchimento.push(gpq);
                    break;
            }
        });
    }

    getActiveIndex()  {
        if (this.userRole === UserRole.SESAB) {
          return 3;
        }

        return 2;
    }

    getPanelGPQFESFHeader() {
        if (this.userRole === UserRole.FESF) {
          return 'Para Aprovar';
        }

        return 'Na Fesf';
    }

    getPanelGPQSESABHeader() {
        if (this.userRole === UserRole.SESAB) {
          return 'Para Aprovar';
        }

        return 'Na Sesab';
    }

}
