import { Injectable } from '@angular/core';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';

@Injectable({
  providedIn: 'root'
})
export class GpqStatusService {

  constructor() { }

  getStatusExibicao(status: String){
    switch (status) {
      case StatusGpq.Aprovado:
        return "Aprovado";
      case StatusGpq.Criado:
        return "Criado";
      case StatusGpq.PendenteMunicipio:
        return "Pendente Município";
      case StatusGpq.Rejeitado:
        return "Rejeitado";
      case StatusGpq.PendenteFesf:
        return "Pendente Fesf";
      case StatusGpq.PendenteSesab:
        return "Pendente Sesab";
      case StatusGpq.Ferias:
        return "Não houve GPQ neste mês"
      case "Gpq ainda nao foi enviado":
        return "Não houve GPQ neste mês";
      case "Bloqueado":
        return "Bloqueado"
      default:
        return ""
    }
  }

  getSeverity(status: String) {
    switch (status) {
      case StatusGpq.Aprovado:
        return 'success';

      case StatusGpq.Criado:
        return 'info';

      case StatusGpq.PendenteMunicipio:
        return 'warning';

      case StatusGpq.PendenteFesf:
        return 'warning';

      case StatusGpq.PendenteSesab:
        return 'warning';

      default:
        return 'danger';
    }
  }
}
