import { Injectable } from '@angular/core';
import { StatusGpq } from '../../../models/StatusGpqEnum';

@Injectable({
    providedIn: 'root'
})
export class DataFormatService {

    constructor() { }
    formatDataToStringMesAno(dataGpq: Date): string {
        const data = new Date(dataGpq);
        const mes = data.getUTCMonth() + 1;
        const ano = data.getUTCFullYear();
        const mesFormatado = mes.toString().padStart(2, '0');
        return `${mesFormatado}/${ano}`;
    }

    formatDataRemoveDataFuso(data: Date): Date {
        const [ano, mes, dia] = data.toString().split('-').map(Number);

        const dataRetorno = new Date();
        dataRetorno.setFullYear(ano, mes - 1, dia);
        return dataRetorno;
    }

    formatDataToISO(data: Date | string): string {
        let date = new Date(data);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    formatDataToStringDiaMesAno(date: Date): string {
        let dataFormatada: string = '';

        if (!date) {
            return dataFormatada;
        }

        const data = new Date(date);
        const dia = data.getUTCDate().toString().padStart(2, '0');
        const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0');
        const ano = data.getUTCFullYear();
        dataFormatada = `${dia}/${mes}/${ano}`;

        return dataFormatada;
    }

    formatTimeToHoraMinuto(time: string | Date): string {
        let horaFormatada = '';

        if (typeof time === 'string') {
            const [hora, minuto, segundo] = time.toString().split(':').map(Number);
            horaFormatada = `${hora.toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`;
        }
        else {
            const hora = time.getHours();
            const minuto = time.getMinutes();
            horaFormatada = `${hora.toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`;
        }

        return horaFormatada;
    }


    formatTimeStringToDate(timeString: string): Date {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);

        // Cria um novo objeto Date com a data atual
        const date = new Date();

        // Define a hora, minutos e segundos
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        date.setMilliseconds(0);

        return date;
    }
}
