import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { HistoricoService } from 'src/app/application/services/historico-service/historico.service';
import { ButtonConfig } from 'src/app/core/models/ButtonConfig';
import { MainHeaderButtons } from 'src/app/core/models/MainHeaderButtons';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { PartoHumanizadoMessageService } from 'src/app/core/service/parto-humanizado-message.service';


@Component({
  selector: 'app-header-com-botoes',
  templateUrl: './header-com-botoes.component.html',
 styleUrls: ['./header-com-botoes.component.scss']
})
export class HeaderComBotoesComponent {

  @Input() title: string = '';
  @Input() buttons: ButtonConfig[] = [];
  @Input() showMainButtons: MainHeaderButtons = {history: true, return: true};
  temMensagemNaoVisualizada: boolean = false;
  gpqId!: number;
  desabilitarBotaoHistorico: boolean = false;

  constructor(
    private router: Router,
    private historicoService: HistoricoService,
    private gpqService: GpqService,
    private messageService: PartoHumanizadoMessageService,) {}

  ngOnInit() {

    this.gpqService.getGpq((gpq) => {
      if(!gpq)
        return;

    this.gpqId = gpq.numeroDoDocumento

    this.desabilitarBotaoHistorico = gpq.statusDoDocumento == StatusGpq.Ferias ? true : false;

    this.buscarMensagens();

    })
  }

  private buscarMensagens(){
    this.historicoService.novaMensage(this.gpqId).subscribe({
        next: (response) => this.temMensagemNaoVisualizada = response,
        error: (response) => this.messageService.showErrorMessage('Error', 'Não foi possível buscar mensagens pendentes')
    })
  }


  navigateLastPage() {
    window.history.back();
  }

  handleHistoricoClick() {
    this.router.navigate(['/historico-gpq']);
  }

  openSplitButtonMenu(event: Event, button: any): void {
    if (button.split && button.model) {
        const splitButtonElement = (event.currentTarget as HTMLElement).querySelector('.p-splitbutton-menubutton') as HTMLElement;
        if (splitButtonElement) {
            splitButtonElement.click(); // Dispara o clique na seta do splitButton
            }
        }
    }
}
