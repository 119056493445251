<div class="card forms-card">
    <div class="forms-card-collection">
        <ng-container *ngIf="isLoading">
            <div *ngFor="let item of formularioItems" 
                 class="forms-card-item clickable background-color-not-ready" 
                 [routerLink]="item.routerLink" 
                 pTooltip="Ausente de Registro ou Confirmação."
                 tooltipPosition="bottom">
                 <i [class]="item.icon"></i>
                <span>{{ item.label }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">
            <div *ngFor="let item of formularioItems" 
                 class="forms-card-item clickable" 
                 [routerLink]="item.routerLink" 
                 [ngClass]="getClass(statusDoDocumento(item.label))"
                 pTooltip="{{ getTooltip(statusDoDocumento(item.label)) }}"
                 tooltipPosition="bottom">
                 <i [class]="item.icon"></i>
                <span>{{ item.label }}</span>
            </div>
        </ng-container>
    </div>
</div>