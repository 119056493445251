import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    templateUrl: './accessdenied.component.html'
})
export class AccessdeniedComponent {

    constructor(private router: Router){

    }

    navigateToLogin(){
        this.router.navigate([''])
    }
}
