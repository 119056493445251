import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { Gpq } from 'src/app/application/models/Gpq';
import { GpqService } from 'src/app/application/services/gpq-service/gpq.service';
import { GpqStatusService } from 'src/app/application/services/gpq-status-service/gpq-status.service';
import { SpnhService } from 'src/app/application/services/spnh-service/spnh.service';
import { UserService } from 'src/app/application/services/user-service/user.service';
import { StatusGpq } from 'src/app/core/models/StatusGpqEnum';
import { UserRole } from 'src/app/core/models/UserRoles';
import { PartoHumanizadoMessageService } from 'src/app/core/service/parto-humanizado-message.service';
import { UsuarioResponse } from './../../../../models/usuarios/UsuarioResponse';

@Component({
  selector: 'app-town-info',
  templateUrl: './town-info.component.html',
  styleUrl: './town-info.component.scss'
})
export class TownInfoComponent  implements OnInit{
  items!: MenuItem[];
  equipe!: UsuarioResponse[];
  expandedRows = {};

  visible: any;
  usuariosDoMunicipio: UsuarioResponse[] = [];
  usuarioSelecionado!: UsuarioResponse;
  isSelected = false


  @Input('lista-gpq')
  listaGpq!: Gpq[];

  gpqSelecionado!: Gpq | null;
  private gpqSub!: Subscription
  searchValueGpqs: string = '';

  constructor(
    private userService: UserService,
    private spnhService: SpnhService,
    private messageService: PartoHumanizadoMessageService,
    private router: Router,
    private gpqService: GpqService,
    private gpqStatusService: GpqStatusService
  ){
    this.popularItems();
  }
  ngOnInit(): void {
    this.gpqSub = this.gpqService.getGpq((gpq) => this.gpqSelecionado = gpq ? gpq : null)

  }

  getStatusGpq(gpq: Gpq) {
    return this.gpqStatusService.getSeverity(gpq.statusDoDocumento)
  }

  getRoleMembro(membro: any) {
    switch (membro.role) {
      case UserRole.SMS:
        return 'info';
      default:
        return 'secondary';
    }
  }

  exibirFormularioAdicionarUsuario() {
    this.visible = !this.visible;
  }

  adicionarNovoUsuarioSpnh(codigoMunicipio: number, email: string){
    this.spnhService.adicionarUsuarioEmSpnh(codigoMunicipio, email)
    .subscribe({
      next: (res) => {
        this.messageService.showSuccessMessage('Sucesso', 'Usuário adicionado ao serviço')
        this.equipe.push(this.usuarioSelecionado);
      },
      error: (res) => this.messageService.showErrorMessage('Erro', 'Não foi possível adicionar o usuário ao serviço')
    })
  }

  navigateToGpq(gpq: Gpq){
    const role = sessionStorage.getItem('role');
    const userRole: UserRole = role ? role as UserRole : UserRole.FESF;

    this.gpqService.selecionarGpq(gpq)

    let roleGpq = "observador";

    if(gpq.statusDoDocumento === StatusGpq.PendenteFesf && userRole === UserRole.FESF ||
      gpq.statusDoDocumento === StatusGpq.PendenteSesab && userRole === UserRole.SESAB){
      roleGpq = 'validador'
    }


    sessionStorage.setItem('role-gpq', roleGpq)
    this.router.navigate(
      ['/validar-gpq'],
    )
  }

  popularItems(){
    this.items = [
      {
        icon: 'pi pi-check',
        label: 'Marcar como Feito',
      },

      {
        icon: 'pi pi-times',
        label: 'Cancelar',
      },
      {
        icon: 'pi pi-external-link',
        label: 'Detalhes',
      },
    ];
  }

  getStatusExibicao(status: string){
    return this.gpqStatusService.getStatusExibicao(status)
  }

  selecionaGpq(gpq: Gpq){
    this.gpqService.selecionarGpq(gpq)
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal(
        (event.target as HTMLInputElement).value,
        'contains'
    );
  }
}
