import { Injectable } from '@angular/core';
import { StatusGpq } from '../../../models/StatusGpqEnum';

@Injectable({
	providedIn: 'root'
})
export class TextFormatService {

	constructor() { }

	formatIdentificador(id: string): string {
		if (id.length == 11) {
			return this.formatCpf(id)
		}
		return this.formatCoren(id)
	}

	formatCpf(cpf: string): string {
		return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
	}

	formatCoren(coren: string): string {
		const prefixo = coren.slice(0, 3);
		const sufixo = coren.slice(3);
		return `COREN-BA ${prefixo}.${sufixo}`
	}

	formatTelefone(tel: string): string {
		if(tel.trim().length == 0) return ''

		const ddd = tel.slice(0, 2);
		const numero = tel.slice(2);
		const prefixo = numero.slice(0, -4);
		const sufixo = numero.slice(-4);
		return `(${ddd}) ${prefixo}-${sufixo}`;
	}

	formatBool(bool: boolean): string {
		return bool == true ? 'Sim' : 'Não'
	}

	// Permite apenas a entrada de letras e letras acentuadas (ex.: campo de nome)
	inputFilter(e: KeyboardEvent) {
		if (!e.key ||
			!/^[a-zA-ZÀ-ÿ]$/.test(e.key) &&
			!['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Enter', ' '].includes(e.key) &&
			!['Insert', 'Delete', 'Escape', 'Home', 'End', 'PageUp', 'PageDown'].includes(e.key) &&
			!e.key.startsWith('F')
		) {
			e.preventDefault();
		}
	}

	formatPermissao(permissao: string): string {
		switch (permissao) {
			case 'ENFO':
				return 'Enfermeira(o) Obstetra'
			case 'SMS':
				return 'Secretaria Municipal de Saúde'
			case 'FESF':
				return 'Fundação Estatal Saúde da Família'
			case 'SESAB':
				return 'Secretaria de Saúde do Estado da Bahia'
			default:
				return ''
		}
	}

	formatTipoSanguineo(tipo: string): string {
		switch (tipo) {
			case 'A_POSITIVO':
				return 'A+'
			case 'A_NEGATIVO':
				return 'A-'
			case 'B_POSITIVO':
				return 'B+'
			case 'B_NEGATIVO':
				return 'B-'
			case 'AB_POSITIVO':
				return 'AB+'
			case 'AB_NEGATIVO':
				return 'AB-'
			case 'O_POSITIVO':
				return 'O+'
			case 'O_NEGATIVO':
				return 'O-'
			default:
				return ''
		}
	}

	formatStatusVoto(voto: string) {
		switch (voto) {
			case StatusGpq.Rejeitado:
				return 'Reprovado';
			case StatusGpq.Aprovado:
				return 'Aprovado';
			case StatusGpq.Submissao:
				return 'Enviado'
			default:
				return ''
		}
	}

	formatMes(mes: Number){
		switch(mes){
		  case 1:
			return "Janeiro"
		  case 2:
			return "Fevereiro"
		  case 3:
			return "Março"
		  case 4:
			return"Abril"
		  case 5:
			return "Maio"
		  case 6:
			return "Junho"
		  case 7:
			return "Julho"
		  case 8:
			return "Agosto"
		  case 9:
			return "Setembro"
		  case 10:
			return "Outubro"
		  case 11:
			return "Novembro"
		  case 12:
			return "Dezembro"
		  default:
			return ""
		}
	  }

	formatMesAbreviado(mes: Number){
		switch(mes){
		  case 0:
			return "Jan";
		  case 1:
			return "Fev";
		  case 2:
			return "Mar";
		  case 3:
			return "Abr";
		  case 4:
			return "Mai";
		  case 5:
			return "Jun";
		  case 6:
			return "Jul";
		  case 7:
			return "Ago";
		  case 8:
			return "Set";
		  case 9:
			return "Out";
		  case 10:
			return "Nov";
		  case 11:
			return "Dez";
		  default:
			return ""
		}
	  }

	  formatReuniao(reuniao: string){
		switch (reuniao) {
		  case 'encontro-gestantes':
			return 'Encontro com grupo de Mulheres';
		  case 'educacao-permanente':
			return 'Atividade de Educação Permanente';
		  case 'reuniao-monitoramento':
			return 'Reunião de Monitoramento';
		  default:
			return '';
		}

	  }
}
