<div class="grid">
    <!-- <p-messages [(value)]="msgs1" class="w-full mx-3" styleClass=" w-full border-round-3xl surface-card font-medium text-color-secondary" [enableService]="false"></p-messages> -->
    <app-header-cards />
    <div class="col-12">
        <div class="grid">
            <div class="col-12 sm:col-12 md:col-12 xl:col-8">
                <div class="card gpq-section" style="min-height: 500px;">
                    <div class="card-header gap-3 pb-2">
                        <div class="card-title">
                            <h6>
                                GPQs - Mês Atual: {{ date | date : "MM/yyyy" }}
                            </h6>
                        </div>
                        <div>
                            <p-calendar
                                [(ngModel)]="date"
                                view="month"
                                (onSelect)="buscarGpqPorData()"
                                [showIcon]="true"
                                [iconDisplay]="'input'"
                                dateFormat="mm/yy"
                                placeholder="Selecionar mês"
                                [readonlyInput]="true"
                            />
                        </div>
                    </div>
                    <!-- CHAMADA DE COMPONENTE -->
                    <div>
                        <p-tabView [scrollable]="true" [activeIndex]="getActiveIndex()" >
                            <p-tabPanel header="Em preenchimento">
                                <app-town-info
                                    [lista-gpq]="listaGpqEmPreenchimento"
                                />
                            </p-tabPanel>
                            <p-tabPanel header="Gest. Municipais">
                                <app-town-info
                                    [lista-gpq]="listaGpqMunicipio"
                                />
                            </p-tabPanel>
                            <p-tabPanel [header]="getPanelGPQFESFHeader()">
                                <app-town-info [lista-gpq]="listaGpqFesf" />
                            </p-tabPanel>
                            <p-tabPanel [header]="getPanelGPQSESABHeader()">
                                <app-town-info [lista-gpq]="listaGpqSesab" />
                            </p-tabPanel>
                            <p-tabPanel header="Aprovados">
                                <app-town-info
                                    [lista-gpq]="listaGpqAprovados"
                                />
                            </p-tabPanel>
                            <p-tabPanel header="Férias">
                                <app-town-info
                                    [lista-gpq]="listaGpqFerias"
                                />
                            </p-tabPanel>
                            <p-tabPanel header="Bloqueados">
                                <app-town-info
                                    [lista-gpq]="listaGpqBloqueados"
                                />
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </div>
            </div>

            <div class="col-12 sm:col-12 md:col-12 xl:col-4">
                <div class="card map-card">
                    <div class="card-header pb-2">
                        <h6>Municípios da Bahia</h6>
                    </div>
                    <!-- CHAMADA DE COMPONENTE -->
                    <app-map class="map-component"/>
                    <div>
                        <h6>Legenda:</h6>
                        <div class="map-label-div">
                            <span class="map-label"></span>
                            <span>Município com SPNH</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 sm:col-12 md:col-12 xl:col-6">
                <div class="card chart-container">
                    <p-chart
                        type="line"
                        [data]="dataLine"
                        [options]="optionsLine"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-12 md:col-12 xl:col-6">
                <div class="card chart-container">
                    <p-chart type="bar" [data]="data" [options]="options" />
                </div>
            </div>
        </div>
    </div>
</div>
