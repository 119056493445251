import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
    let token = sessionStorage.getItem('token')

    if(token){
        return true
    }

    const router = inject(Router);
    //Redireciona para página de login
    router.navigate([''])

    return false;
};
