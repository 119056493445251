<!-- <button
    class="layout-config-button p-link"
    type="button"
    (click)="onConfigButtonClick()"
>
    <i class="pi pi-cog"></i>
</button> -->

<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="layout-config-sidebar w-18rem"
>
    <div>
        <h5>Configurações</h5>
        <div style="display: flex; flex-direction: column; gap: 3px;">
            <a
                *ngIf="isUsuarioFesf"
                class="flex align-items-center hover:text-primary-500 transition-duration-200 cursor-pointer"
                pStyleClass="@grandparent"
                enterFromClass="ng-hidden"
                enterActiveClass="px-scalein"
                leaveToClass="ng-hidden"
                leaveActiveClass="px-fadeout"
                (click)="navigateToMetas()"
            >
                <i class="pi pi-fw pi-calendar mr-2 cursor-pointer"></i>
                <span class="cursor-pointer">Metas</span>
            </a>
        </div>
    </div>

    <!-- <h5>Themes</h5>
    <div class="flex flex-wrap row-gap-3">
        <div class="w-3" *ngFor="let theme of componentThemes">
            <button
                type="button"
                class="cursor-pointer p-link w-2rem h-2rem border-circle flex-shrink-0 flex align-items-center justify-content-center"
                (click)="changeTheme(theme.name)"
                [ngStyle]="{ 'background-color': theme.color }"
            >
                <i
                    *ngIf="theme.name == this.layoutService.config().theme"
                    class="pi pi-check text-white"
                ></i>
            </button>
        </div>
    </div> -->

    <h5>Escala</h5>
    <div class="flex align-items-center">
        <button
            icon="pi pi-minus"
            type="button"
            pButton
            (click)="decrementScale()"
            class="p-button-text p-button-rounded w-2rem h-2rem mr-2"
            [disabled]="scale === scales[0]"
        ></button>
        <div class="flex gap-2 align-items-center">
            <i
                class="pi pi-circle-fill text-300"
                *ngFor="let s of scales"
                [ngClass]="{ 'text-primary-500': s === scale }"
            ></i>
        </div>
        <button
            icon="pi pi-plus"
            type="button"
            pButton
            (click)="incrementScale()"
            class="p-button-text p-button-rounded w-2rem h-2rem ml-2"
            [disabled]="scale === scales[scales.length - 1]"
        ></button>
    </div>

    <ng-container *ngIf="!minimal">
        <h5>Tipo Menu</h5>
        <div class="flex flex-wrap row-gap-3">
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                    name="menuMode"
                    value="static"
                    [(ngModel)]="menuMode"
                    inputId="mode1"
                ></p-radioButton>
                <label for="mode1">Static</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                    name="menuMode"
                    value="overlay"
                    [(ngModel)]="menuMode"
                    inputId="mode2"
                ></p-radioButton>
                <label for="mode2">Overlay</label>
            </div>
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                    name="menuMode"
                    value="drawer"
                    [(ngModel)]="menuMode"
                    inputId="mode7"
                ></p-radioButton>
                <label for="mode7">Drawer</label>
            </div>
        </div>

        <h5>Tema Menu</h5>
        <div class="flex flex-wrap row-gap-3">
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                    name="menuTheme"
                    value="light"
                    [(ngModel)]="menuTheme"
                    [disabled]="
                        this.layoutService.config().colorScheme == 'dark' ||
                        this.layoutService.isHorizontal()
                    "
                    inputId="menutheme-light"
                ></p-radioButton>
                <label for="menutheme-light">Light</label>
            </div>
            <div class="flex align-items-center gap-2 w-6 pl-2">
                <p-radioButton
                    name="menuTheme"
                    value="dark"
                    [(ngModel)]="menuTheme"
                    [disabled]="
                        this.layoutService.config().colorScheme == 'dark' ||
                        this.layoutService.isHorizontal()
                    "
                    inputId="menutheme-dark"
                ></p-radioButton>
                <label for="menutheme-dark">Dark</label>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!minimal">
        <h5>Estilo dos Campos</h5>
        <div class="flex flex-wrap row-gap-3">
            <div class="flex align-items-center gap-2 w-6">
                <p-radioButton
                    name="inputStyle"
                    value="outlined"
                    [(ngModel)]="inputStyle"
                    inputId="outlined_input"
                ></p-radioButton>
                <label for="outlined_input">Outlined</label>
            </div>
            <div class="flex align-items-center gap-2 w-6 pl-2">
                <p-radioButton
                    name="inputStyle"
                    value="filled"
                    [(ngModel)]="inputStyle"
                    inputId="filled_input"
                ></p-radioButton>
                <label for="filled_input">Filled</label>
            </div>
        </div>

        <!-- <h5>Ripple Effect</h5>
        <p-inputSwitch [(ngModel)]="ripple"></p-inputSwitch> -->
    </ng-container>
</p-sidebar>
