import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HistoricoGpq } from '../../models/HistoricoGpt';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UsuarioResponse } from '../../models/usuarios/UsuarioResponse';
import { VisualizacaoMensagens } from '../../models/VisualizacoesMensagens';

@Injectable({
  providedIn: 'root'
})
export class HistoricoService {

  private apiUrl = environment.API_URL + 'historico'


  constructor(private http: HttpClient) { }

  private setHeadersConfiguration() {
    let token = sessionStorage.getItem('token')
    return new HttpHeaders({
      'secret': environment.API_SECRET, 
      'Authorization': `Bearer ${token}`
    });
  }

  getUsuario(): UsuarioResponse | null {
    const userStorage = sessionStorage.getItem('userAuth');
    if(userStorage){
      return JSON.parse(userStorage);
    }
    return null;
  }

  historicoGpq(gpqId: number): Observable<HistoricoGpq[]>{
    const headers = this.setHeadersConfiguration();
    return this.http.get<HistoricoGpq[]>(this.apiUrl + "/gpq/" + gpqId, {
      headers: headers
    });
  }

  mensagensGpq(gpqId: number): Observable<HistoricoGpq[]>{
    const headers = this.setHeadersConfiguration();
    return this.http.get<HistoricoGpq[]>(this.apiUrl + "/mensagens/" + gpqId, {
      headers: headers
    });
  }

  visualizacoesMensagem(gpqId: number): Observable<VisualizacaoMensagens[]>{
    const headers = this.setHeadersConfiguration();
    return this.http.get<VisualizacaoMensagens[]>(this.apiUrl + "/mensagens/visualizacoes/" + gpqId, {
      headers: headers
    });
  }
  
  novaMensage(gpqId: number): Observable<boolean>{
    const headers = this.setHeadersConfiguration();
    return this.http.get<boolean>(this.apiUrl + "/mensagens/nao-visualizada/" + gpqId, {
      headers: headers
    });
  }
}
