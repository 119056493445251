import { Component, OnInit } from '@angular/core';
import { UserRole } from 'src/app/core/models/UserRoles';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    formsItems =
    [
        {
            label: 'Consultas de Pré-Natal',
            icon: 'pi pi-file-o',
            routerLink: ['/visita', 'pre-natal'],
        },
        {
            label: 'Visitas de Vinculação',
            icon: 'pi pi-fw pi-link',
            routerLink: ['/visita', 'vinculacao'],
        },
        {
            label: 'Encontro com grupo de Mulheres',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/reuniao', 'encontro-gestantes'],
        },
        {
            label: 'Educação Permanente',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/reuniao', 'educacao-permanente'],
        },
        {
            label: 'Reunião de Monitoramento',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/reuniao', 'reuniao-monitoramento'],
        },
        {
            label: 'Partos do Mês',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/partos'],
        },
        {
            label: 'Gestações de Risco',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/riscos', 'gestacao'],
        },
        {
            label: 'Partos de Risco',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/riscos', 'parto'],
        },
    ]

    relatorioItems =
    [
        {
            label: 'Relatório Mensal',
            icon: 'pi pi-file-o',
            routerLink: ['/relatorio-mensal'],
        },
        {
            label: 'Relatórios Consolidados',
            icon: 'pi pi-file-o',
            routerLink: ['/relatorio-consolidado'],
        },
        // {
        //     label: 'Relatórios Consolidados(Gráficos)',
        //     icon: 'pi pi-file-o',
        //     routerLink: ['/relatorio-consolidado-graficos'],
        // },
    ]

    pessoasItem =
    [
        {
            label: 'Pessoas',
            icon: 'pi pi-user',
            routerLink: ['/pessoas'],
        },
    ]

    usuariosItem =
    [
        {
            label: 'Usuários',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/users'],
        },
    ]

    spnhItem =
    [
        {
            label: 'SPNH',
            icon: 'pi pi-fw pi-desktop',
            routerLink: ['/spnh'],
        },
    ]


    getMenuItems(): any[] {
        switch (sessionStorage.getItem('role')) {
            case UserRole.ENFERMEIRA:
                return [
                    {
                        label: 'Home',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/home-enfermeira'],
                    },
                    ...this.pessoasItem,
                    ...this.formsItems,
                ];
            case UserRole.SMS:
                return [
                    {
                        label: 'Home',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/home-ssm'],
                    },
                    ...this.pessoasItem,
                    ...this.formsItems,
                ];
            case UserRole.SESAB:
                return [
                    {
                        label: 'Home',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/home-sesab'],
                    },
                    ...this.spnhItem,
                    ...this.pessoasItem,
                    ...this.formsItems,
                    ...this.relatorioItems,
                ];
            case UserRole.FESF:
                return [
                    {
                        label: 'Home',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/home-fesf'],
                    },
                    ...this.usuariosItem,
                    ...this.spnhItem,
                    ...this.pessoasItem,
                    ...this.formsItems,
                    ...this.relatorioItems,
                ];
            case UserRole.ADMIN:
                return [
                    {
                        label: 'Home',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/home-admin'],
                    },
                    ...this.usuariosItem,
                    ...this.spnhItem,
                    ...this.pessoasItem,
                    ...this.formsItems,
                    ...this.relatorioItems,
                ];
            default:
                return [
                    {
                        label: 'Home',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/home-sesab'],
                    },
                    {
                        label: 'Usuários',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/users'],
                    },
                ];
        }
    }

    ngOnInit() {
        this.model = [
            {
                label: '',
                icon: 'pi pi-home',
                items: this.getMenuItems(),
            },
        ];
    }
}
