import {
    Component,
    ElementRef,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { LayoutService } from 'src/app/core/service/app.layout.service';
import { AppSidebarComponent } from 'src/app/core/components/layout/sidebar/menu/app.sidebar.component';
import { debounceTime, fromEvent, map, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { PerfilService } from 'src/app/application/services/perfil-service/perfil.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopbarComponent implements OnInit {
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    activeItem!: number;
    isScrolled: Boolean = false;
    scrollSubscription!: Subscription;


    urlImage: string = ''

    constructor(public layoutService: LayoutService, public el: ElementRef, private router: Router, private perfilService: PerfilService) {}

    ngOnInit(): void {
        this.scrollSubscription = fromEvent(window, 'scroll')
            .pipe(
                debounceTime(10), // Evita chamadas excessivas
                map(() => document.documentElement.scrollTop)
            )
            .subscribe((scrollTop) => {
                this.isScrolled = scrollTop > 100;
            });

        this.perfilService.imageUrl$.subscribe(url => {
            this.urlImage = url;
        })
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onStyleButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    onLogout(){
        sessionStorage.clear()
        this.router.navigate(['']).then(() => {
             window.location.reload()
        })
    }
}
