import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnfermeiraService {
  private readonly apiUrl = environment.API_URL + 'enfermagem'

  constructor(private http: HttpClient) {}

  private setHeadersConfiguration() {
    let token = sessionStorage.getItem('token')
    return new HttpHeaders({
      'secret': environment.API_SECRET, 
      'Authorization': `Bearer ${token}`
    });
  }

  cadastrar(data: any): Observable<any> {
    return this.http.post(this.apiUrl, data, {
      headers: this.setHeadersConfiguration()
    })
  }
}
