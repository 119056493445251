import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { GpqStatusService } from 'src/app/application/services/gpq-status-service/gpq-status.service';
import { DataFormatService } from './../../format-service/data-format/data-format.service';

@Injectable({
  providedIn: 'root'
})
export class ExportRelatorioMensalPdfService {

  posicaoAtualLinhaRelatorio: number = 150;
  numeraçaoTopicos = 0;

  constructor(
    private gpqStatusService: GpqStatusService,
    private dataFormatService: DataFormatService
  ) { }



  public exportarPdf(relatorio: any, date: Date): void {
    const doc = new jsPDF();

    const dataDoRelatorio = this.dataFormatService.formatDataToStringMesAno(date);
    const titulo = `Relatório de ${relatorio.nomeDoMunicio} do mês ${dataDoRelatorio}`;

    doc.setFont("Times", "bold");
    doc.setFontSize(16);
    const textoLargura = doc.getTextWidth(titulo);
    const posicaoCentralizadaX = (doc.internal.pageSize.getWidth() - textoLargura) / 2;
    doc.text(titulo, posicaoCentralizadaX, 60);

    this.adicionarTabelaEquipePdf(doc, relatorio);
    this.adicionarInfosGpqPdf(doc, relatorio);
    this.adicionarQuantitativoDeDadosPorAcao(doc, relatorio);
    this.adicionarRelatorioDetalhadoDePartos(doc, relatorio);
    this.adicionarRelatorioIndividualizadoDePartos(doc, relatorio);
    this.adicionarRelatorioDetalhadoDeVisitas(doc, relatorio);
    this.adicionarRelatorioDetalhadoDeVisitasIndividualizada(doc, relatorio);
    this.adicionarRelatorioDetalhadoDeReunioes(doc, relatorio);
    this.adicionarRelatorioIndividualizadoDeReunioes(doc, relatorio);
    this.adicionarRelatorioDetalhadoDeGestacaoDeRisco(doc, relatorio);
    this.adicionarRelatorioIndividualDeRiscos(doc, relatorio);

    const nomeArquivo = this.gerarNomeArquivo(relatorio.nomeDoMunicio, date);

    this.adicionarImagemComRodape(doc, nomeArquivo);

    this.posicaoAtualLinhaRelatorio = 150;
    this.numeraçaoTopicos = 0;
}


    // ------------------------------- Métodos Auxiliares ------------------------------------

    private adicionarLinha(doc: jsPDF, valorParaIncrementar: number){
        doc.line(25, this.controleDeLinha(doc, 0),  doc.internal.pageSize.width - 25, this.controleDeLinha(doc, valorParaIncrementar));
    }


    private controleDeLinha(doc: jsPDF, valorParaIncrementar: number): number{

        if(this.posicaoAtualLinhaRelatorio >= doc.internal.pageSize.height - 40){
            doc.addPage()
            //this.adicionarImagemComRodape(doc)
            this.posicaoAtualLinhaRelatorio = 55;
        }

        let posicaoAInserir = this.posicaoAtualLinhaRelatorio;
        this.posicaoAtualLinhaRelatorio += valorParaIncrementar;
        return posicaoAInserir;
    }

    private inserirTextoComQuebra(doc: jsPDF, texto: string, margemEsquerda: number = 20) {
        const distanciaParaProximaLinha = 8;
        const larguraMaxima = doc.internal.pageSize.width - 50; // Considera margens
        const palavras = texto.split(" ");
        let linhaAtual = "";

        let primeiraLinha = true;
        for (const palavra of palavras) {
            const novaLinha = linhaAtual ? `${linhaAtual} ${palavra}` : palavra;
            const dimensoes = doc.getTextDimensions(novaLinha);

            if (dimensoes.w > larguraMaxima) {

                // Se a nova linha exceder a largura máxima, insere a linha atual
                doc.text(linhaAtual, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
                linhaAtual = palavra; // Começa uma nova linha com a palavra atual
                if(primeiraLinha){
                    margemEsquerda += 3;
                    primeiraLinha = false;
                }

            } else {
                linhaAtual = novaLinha; // Adiciona a palavra à linha atual
            }
        }

        // Insere a última linha, se houver
        if (linhaAtual) {
            doc.text(linhaAtual, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        }
    }

    private getStatusExibicao(relatorio: any) {
        return this.gpqStatusService.getStatusExibicao(relatorio.estadoDeAprovacaoDoGpq)
    }

    private getRegistradoDate(data: any) {
        return new Date(data).toLocaleDateString();
    }

    private getOcorreuNoHpp(ocorreuNohpp: boolean) {
        if (ocorreuNohpp) {
            return 'Sim';
        }
        return 'Não';
    }

    private carregarImagem(src: string, callback: (image: HTMLImageElement) => void): void {
        const image = new Image();
        image.src = src;
        image.onload = () => callback(image);
    }

    private adicionarHeader(doc: jsPDF, imageHeader: HTMLImageElement): void {
        const pageWidth = doc.internal.pageSize.width;
        const headerMargin = 20; // Margem de 20px para o cabeçalho

        // Define largura e altura do cabeçalho mantendo a proporção
        const headerWidth = pageWidth - 2 * headerMargin;
        const headerHeight = (imageHeader.naturalHeight / imageHeader.naturalWidth) * headerWidth;

        // Adiciona o cabeçalho centralizado na parte superior da página
        doc.addImage(imageHeader, 'PNG', headerMargin, 10, headerWidth, headerHeight);
    }

    private adicionarFooter(doc: jsPDF, imageFooter: HTMLImageElement): void {
        const pageWidth = doc.internal.pageSize.width;
        const footerWidth = 70;
        const footerHeight = (imageFooter.naturalHeight / imageFooter.naturalWidth) * footerWidth;

        // Adiciona o rodapé no canto inferior direito
        const xPos = pageWidth - footerWidth - 20;
        const yPos = doc.internal.pageSize.height - footerHeight - 10;
        doc.addImage(imageFooter, 'PNG', xPos, yPos, footerWidth, footerHeight);

        doc.setFontSize(8)
         // Adiciona o texto no canto inferior esquerdo
         const textXPos = 20; // Margem de 10 à esquerda
         const textYPos = doc.internal.pageSize.height - 10; // Posicionamento vertical
         doc.text("Sistema de Gestão de Partos Humanizados - https://gph.fesfsus.ba.gov.br/", textXPos, textYPos);
    }

    private adicionarHeaderFooterTodasPaginas(doc: jsPDF, imageHeader: HTMLImageElement, imageFooter: HTMLImageElement): void {
        const totalPages = doc.getNumberOfPages();

        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            doc.setPage(pageNumber);
            this.adicionarHeader(doc, imageHeader);
            this.adicionarFooter(doc, imageFooter);
        }
    }

    private adicionarImagemComRodape(doc: jsPDF, nomeArquivo: string): void {
        // Carrega as duas imagens (cabeçalho e rodapé) e aplica a todas as páginas antes de salvar
        this.carregarImagem('assets/images/relatorio/fesf-document-header.png', (imageHeader) => {
            this.carregarImagem('assets/images/relatorio/fesf-document-footer.png', (imageFooter) => {
                this.adicionarHeaderFooterTodasPaginas(doc, imageHeader, imageFooter);
                // Salvar o documento com o nome especificado
                doc.save(nomeArquivo);
            });
        });
    }



    private adicionarTabelaEquipePdf(doc: jsPDF, relatorio: any) {

        doc.setFontSize(12);
        doc.setFont("Times", "bold");
        doc.text("Equipe: ", 20, 70);

        const membros = relatorio.equipe.membros;

        const headers = [["Nome", "Função"]];

        const rows = membros.map((membro: any) => [
            membro.dadosCadastrais.nome,
            membro.permissao
        ]);

        const maxRowsPerPage = 13;
        let startY = 75;

        for (let i = 0; i < rows.length; i += maxRowsPerPage) {
            const rowsToDisplay = rows.slice(i, i + maxRowsPerPage);

            if (i > 0) {
                doc.addPage();
                startY = 55;
            }

            autoTable(doc, {
                head: headers,
                body: rowsToDisplay,
                startY: startY,
                margin: { top: 10, right: 20, bottom: 10, left: 20 },
                styles: { fontSize: 10, cellPadding: 2 },
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                theme: 'grid',
            });

            this.posicaoAtualLinhaRelatorio = (doc as jsPDF & { lastAutoTable: { finalY: number } }).lastAutoTable.finalY + 15;
        }
    }



    private adicionarInfosGpqPdf(doc: jsPDF, relatorio: any){

        const distanciaParaProximaLinha = 8
        const margemEsquerda = 25
        doc.setFontSize(14)
        doc.setFont("Times", "bold")
        doc.text("Gpq", 20, this.controleDeLinha(doc, 12))

        doc.setFontSize(12)
        doc.setFont("Times", "normal")
        doc.text(`•  Estado da GPQ do mês: ${this.getStatusExibicao(relatorio)}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha))
        doc.text(`•  Partos esperados para o próximo mês: ${relatorio.partosProvaveisParaOProximoMes}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha))
        doc.text(`•  Partos realizados: ${relatorio.relatorioDePartosRealizados.quantidade}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha))
        doc.text(`•  Reuniões realizadas: ${relatorio.relatorioDeReunioes.quantidadeDeReunioes}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha))
        doc.text(`•  Visitas: ${relatorio.relatorioDeVisitasMensal.total}`, margemEsquerda, this.controleDeLinha(doc, 12))

    }

    private adicionarQuantitativoDeDadosPorAcao(doc: jsPDF, relatorio: any){

        if(relatorio.gpq == null)
            return

        this.numeraçaoTopicos++;
        const margemEsquerda = 30
        doc.setFontSize(14)
        doc.setFont("Times", "bold")
        doc.text(`${this.numeraçaoTopicos}.    Quantitativo de dados da GPQ agrupado por ação`, 20, this.controleDeLinha(doc, 12))


        let numeracaoSubtopico = 0;
        for(const acao of relatorio.gpq.respostas ){
            numeracaoSubtopico++;
            doc.text("", 20, this.controleDeLinha(doc, 4));
            doc.setFontSize(12);
            doc.setFont("Times", "bold");
            doc.text(`${this.numeraçaoTopicos}.${numeracaoSubtopico}.0 ${acao.title}`, 25, this.controleDeLinha(doc, 12));

            if(acao.questions) {
                doc.setFont("Times", "normal")
                for(const resposta of acao.questions){
                    doc.setFontSize(10);

                    this.inserirTextoComQuebra(doc, `•  ${resposta.title}: ${resposta.answer}`, margemEsquerda);
                }
            }
        }
        doc.text("", 20, this.controleDeLinha(doc, 12));
    }

    private adicionarRelatorioDetalhadoDePartos(doc: jsPDF, relatorio: any) {
        this.numeraçaoTopicos++;
        const margemEsquerda = 30;
        const distanciaParaProximaLinha = 8;
        doc.setFontSize(14);
        doc.setFont("Times", "bold")
        doc.text(`${this.numeraçaoTopicos}.    Relatório detalhado de partos ocorridos no mês`, 20, this.controleDeLinha(doc, 12));

        doc.setFontSize(10);
        doc.setFont("Times", "normal")
        doc.text(`•  Partos realizados: ${relatorio.relatorioDePartosRealizados.quantidade}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Partos realizados no HPP: ${relatorio.relatorioDePartosRealizados.ocorridosNoHpp}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Partos realizados fora do HPP: ${relatorio.relatorioDePartosRealizados.ocorridosForaDoHpp}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Partos que tiveram consulta com ENFO: ${relatorio.relatorioDePartosRealizados.comConsultaComENFO}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Partos que não tiveram consulta com ENFO: ${relatorio.relatorioDePartosRealizados.semConsultaComENFO}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Partos que tiveram pré-natal: ${relatorio.relatorioDePartosRealizados.comPreNatal}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Partos que não tiveram pré-natal: ${relatorio.relatorioDePartosRealizados.semPreNatal}`, margemEsquerda, this.controleDeLinha(doc, 12));
    }

    private adicionarRelatorioIndividualizadoDePartos(doc: jsPDF, relatorio: any) {


        if( relatorio.relatorioDePartosRealizados.relatorio
            || relatorio.relatorioDePartosRealizados.relatorio.length == 0){
            return
        }

        const margemEsquerda = 30
        const distanciaParaProximaLinha = 8;
        doc.setFontSize(14);
        doc.setFont("Times", "bold")
        doc.text(`${this.numeraçaoTopicos}.    Relatório individualizado de cada parto`, 20, this.controleDeLinha(doc, 12));
        this.numeraçaoTopicos++;
        const totalPartos = relatorio.relatorioDePartosRealizados.relatorio.length;

        for (let i = 0; i < totalPartos; i++) {
            const parto = relatorio.relatorioDePartosRealizados.relatorio[i];
            doc.setFontSize(10);
            doc.setFont("Times", "normal")
            doc.text(`Nome da gestante: ${parto.gestante.dadosCadastrais.nome}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Ocorreu no HPP?: ${this.getOcorreuNoHpp(parto.parto.ocorreuNoHpp)}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Data do parto: ${this.getRegistradoDate(parto.parto.dataDoParto)}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));

            if (parto.parto.dataDaConsultaComEnfermeiroObstetra) {
                doc.text(`Data da consulta com ENFO: ${this.getRegistradoDate(parto.parto.dataDaConsultaComEnfermeiroObstetra)}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            } else {
                doc.text("Data da consulta com ENFO: Não houve", margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            }

            if (parto.parto.dataDaVisitaComOPreNatal) {
                doc.text(`Data da visita com o pré-natal: ${this.getRegistradoDate(parto.parto.dataDaVisitaComOPreNatal)}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            } else {
                doc.text("Data da visita com o pré-natal: Não houve", margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            }

            doc.text(`Registrado em: ${this.getRegistradoDate(parto.registradoEm)}`, margemEsquerda, this.controleDeLinha(doc, 12));

            if (i < totalPartos - 1) {
                this.adicionarLinha(doc, 12);
            }
        }
    }

    private adicionarRelatorioDetalhadoDeVisitas(doc: jsPDF, relatorio: any) {
        this.numeraçaoTopicos++;
        const margemEsquerda = 30
        const distanciaParaProximaLinha = 8;
        doc.setFontSize(14);
        doc.setFont("Times", "bold")
        doc.text(`${this.numeraçaoTopicos}.    Relatório detalhado de Visitas`, 20, this.controleDeLinha(doc, 12));

        doc.setFontSize(10);
        doc.setFont("Times", "normal")
        doc.text(`•  Total de visitas: ${relatorio.relatorioDeVisitasMensal.total}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Total de pré-natais realizados: ${relatorio.relatorioDeVisitasMensal.quantidadeDeVisitasPreNatal}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Total de visitas de Vinculação: ${relatorio.relatorioDeVisitasMensal.quantidadeDeVisitasVinculacao}`, margemEsquerda, this.controleDeLinha(doc, 12));
    }


    private adicionarRelatorioDetalhadoDeVisitasIndividualizada(doc: jsPDF, relatorio: any) {

        if( relatorio.relatorioDeVisitasMensal.relatorios == null
            || relatorio.relatorioDeVisitasMensal.relatorios.length == 0
        ){
            return
        }
        this.numeraçaoTopicos++;
        const margemEsquerda = 30
        const distanciaParaProximaLinha = 8;
        doc.setFontSize(14);
        doc.setFont("Times", "bold")
        doc.text(`${this.numeraçaoTopicos}.    Relatório detalhado de Visitas individualizada`, 20, this.controleDeLinha(doc, 12));

        const totalVisitas = relatorio.relatorioDeVisitasMensal.relatorios.length;

        for (let i = 0; i < totalVisitas; i++) {
            const visita = relatorio.relatorioDeVisitasMensal.relatorios[i];
            doc.setFontSize(10);
            doc.setFont("Times", "normal")
            doc.text(`Nome da Gestante: ${visita.gestante.dadosCadastrais.nome}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Data da visita: ${this.getRegistradoDate(visita.data)}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`DPP: ${this.getRegistradoDate(visita.dpp)}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Ig: ${visita.ig}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Paridade: ${visita.paridade}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Tipo: ${visita.tipo}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Registrada em: ${this.getRegistradoDate(visita.registradoEm)}`, margemEsquerda, this.controleDeLinha(doc, 12));

            if (i < totalVisitas - 1) {
                this.adicionarLinha(doc, 12);
            }
        }
    }

    private adicionarRelatorioDetalhadoDeReunioes(doc: jsPDF, relatorio: any) {
        this.numeraçaoTopicos++;
        const margemEsquerda = 30
        const distanciaParaProximaLinha = 8;
        doc.setFontSize(14);
        doc.setFont("Times", "bold")
        doc.text(`${this.numeraçaoTopicos}.    Relatório detalhado de Reuniões`, 20, this.controleDeLinha(doc, 12));

        doc.setFontSize(10);
        doc.setFont("Times", "normal")
        doc.text(`•  Reuniões realizadas: ${relatorio.relatorioDeReunioes.quantidadeDeReunioes}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Participações registradas: ${relatorio.relatorioDeReunioes.totalDeParticipacoes}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Encontros com Gestantes: ${relatorio.relatorioDeReunioes.totalDeAtividadesDeEncontroComGestantes}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Reuniões de Monitoramento: ${relatorio.relatorioDeReunioes.totalDeReunioesDeMonitoramento}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
        doc.text(`•  Atividades de Educação Permanente: ${relatorio.relatorioDeReunioes.totalDeAtividadesEducacaoPermanente}`, margemEsquerda, this.controleDeLinha(doc, 12));
    }

    private adicionarRelatorioIndividualizadoDeReunioes(doc: jsPDF, relatorio: any) {
        if( relatorio.relatorioDeReunioes.reunioes == null
            || relatorio.relatorioDeReunioes.reunioes.length == 0
        ){
            return
        }
        this.numeraçaoTopicos++;
        const margemEsquerda = 35
        const distanciaParaProximaLinha = 8;
        doc.setFontSize(14);
        doc.setFont("Times", "bold")
        doc.text(`${this.numeraçaoTopicos}.    Relatório individualizado de reuniões`, 20, this.controleDeLinha(doc, 12));

        const totalReunioes = relatorio.relatorioDeReunioes.reunioes.length;

        for (let i = 0; i < totalReunioes; i++) {

            const reuniao = relatorio.relatorioDeReunioes.reunioes[i];
            doc.setFontSize(10);
            doc.setFont("Times", "bold")
            doc.text(`Tipo: ${reuniao.tipo}`, 30, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.setFont("Times", "normal")
            this.inserirTextoComQuebra(doc, `Tema da reunião: ${reuniao.temas}`, margemEsquerda);
            doc.text(`Data: ${this.getRegistradoDate(reuniao.data)}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            this.inserirTextoComQuebra(doc, `Local: ${reuniao.local}`, margemEsquerda);
            doc.text(`Participantes: ${reuniao.totalDeParticipantes}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Registrada em: ${this.getRegistradoDate(reuniao.registradaEm)}`, margemEsquerda, this.controleDeLinha(doc, 12));

            if (i < totalReunioes - 1) {
                this.adicionarLinha(doc, 12);
            }
        }
    }


    private adicionarRelatorioDetalhadoDeGestacaoDeRisco(doc: jsPDF, relatorio: any) {
        const margemEsquerda = 30
        const distanciaParaProximaLinha = 8;
        this.numeraçaoTopicos++;
        doc.setFontSize(14);
        doc.setFont("Times", "bold");
        doc.text(`${this.numeraçaoTopicos}.    Relatório detalhado de gestação de risco`, 20, this.controleDeLinha(doc, 12));

        doc.setFontSize(10);
        doc.setFont("Times", "normal")
        doc.text(`•  Riscos registrados: ${relatorio.relatoriosDeRiscosDeGestacao.totalDeRiscosRegistrados}`, margemEsquerda, this.controleDeLinha(doc, 12));
    }

    private adicionarRelatorioIndividualDeRiscos(doc: jsPDF, relatorio: any) {
        if(relatorio.relatoriosDeRiscosDeGestacao.riscosGestacoes == null
            || relatorio.relatoriosDeRiscosDeGestacao.riscosGestacoes.length == 0
        ){
            return
        }
        this.numeraçaoTopicos++;
        const margemEsquerda = 30
        const distanciaParaProximaLinha = 8;
        doc.setFontSize(14);
        doc.setFont("Times", "bold")
        doc.text(`${this.numeraçaoTopicos}.    Relatório individual de riscos`, 20, this.controleDeLinha(doc, 12));

        const totalRiscos = relatorio.relatoriosDeRiscosDeGestacao.riscosGestacoes.length;

        for (let i = 0; i < totalRiscos; i++) {
            doc.setFont("Times", "normal")
            const risco = relatorio.relatoriosDeRiscosDeGestacao.riscosGestacoes[i];
            doc.setFontSize(10);
            this.inserirTextoComQuebra(doc, `Risco: ${risco.descricao}`, margemEsquerda);
            doc.text(`Gestante: ${risco.pessoa.dadosCadastrais.nome}`, margemEsquerda, this.controleDeLinha(doc, distanciaParaProximaLinha));
            doc.text(`Registrado em: ${this.getRegistradoDate(risco.registradoEm)}`, margemEsquerda, this.controleDeLinha(doc, 12));

            if (i < totalRiscos - 1) {
                this.adicionarLinha(doc, 12);
            }
        }
    }


    gerarNomeArquivo(municipio: string, data: Date | string): string {
        const municipioFormatado = municipio.replace(/\s+/g, '');
        const dataString = new Date(data).toISOString().split('T')[0];
        const dataFormatada = this.alterarFormatoData(dataString).replace('/', '');
        return `SGPH_RelatorioMensal_${municipioFormatado}_${dataFormatada}.pdf`;
    }

    private alterarFormatoData(dataGpq: string): string {
        const data = new Date(dataGpq);
        const mes = data.getUTCMonth() + 1;
        const ano = data.getUTCFullYear();
        const mesFormatado = mes.toString().padStart(2, '0');
        return `${mesFormatado}/${ano}`;
    }


}
