<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-profile-sidebar w-full sm:w-25rem">
    <!-- <div class="p-0 py-3weather-section">
        <div class="flex align-items-center justify-content-between mb-3">
            <h6 class="m-0">Hoje</h6>
        </div>
        <div class="weather flex align-items-center py-3 px-4 bg-center bg-no-repeat bg-cover border-round-lg" style="box-shadow: 0px 10px 40 rgba(#293241, 6%); background-image: url('../../../../../../assets/images/rightpanel/asset-weather.png')">
            <img src="../../../../../assets/images/rightpanel/trovoada.png" alt="projeto-base-layout" style="height: 60px" />
            <div class="ml-3">
                <h6 class="m-0 mb-1" style="color: rgb(83 88 99)">RECIFE, {{ this.currentDate }}</h6>
                <h1 class="m-0" style="color: rgb(83 88 99)">22º</h1>
            </div>
        </div>
        <div>
            Devido às fortes chuvas de hoje, alguns pacientes podem perder seus compromissos, fique de olho!
        </div>
    </div> -->
    <div class="p-0 ">
        <div class="flex align-items-center justify-content-between mb-3">
            <h5 class="m-0">Informações</h5>
        </div>

    </div>

    <app-useful-sector-information></app-useful-sector-information>
<!--
    <div class="p-0 favorites-section mt-4">
        <div class="flex align-items-center justify-content-between mb-3">
            <h6 class="m-0">Outros Sistemas</h6>
        </div>
        <div class="favorite-items flex align-items-center flex-wrap" style="margin: -7px">
            <a
                href="#"
                class="favorite-item hover:surface-50 surface-0 flex align-items-center justify-content-center border-round-xl m-2 transition-all transition-duration-200"
                style="width: 80px; height: 80px; box-shadow: 0px 0px 2px rgba(41, 50, 65, 0.3), 0px 1px 2px rgba(41, 50, 65, 0.2); background-color: rgba(41, 127, 255, 0.03)"
            >
                <img src="../../../../../../assets/images/rightpanel/sistema-1.svg" alt="outro sistema" />
            </a>
            <a
                href="#"
                class="favorite-item hover:surface-50 surface-0 flex align-items-center justify-content-center border-round-xl m-2 transition-all transition-duration-200"
                style="width: 80px; height: 80px; box-shadow: 0px 0px 2px rgba(41, 50, 65, 0.3), 0px 1px 2px rgba(41, 50, 65, 0.2); background-color: rgba(41, 127, 255, 0.03)"
            >
                <img src="../../../../../../assets/images/rightpanel/sistema-2.svg" alt="outro sistema" />
            </a>
            <a
                href="#"
                class="favorite-item hover:surface-50 surface-0 flex align-items-center justify-content-center border-round-xl m-2 transition-all transition-duration-200"
                style="width: 80px; height: 80px; box-shadow: 0px 0px 2px rgba(41, 50, 65, 0.3), 0px 1px 2px rgba(41, 50, 65, 0.2); background-color: rgba(41, 127, 255, 0.03)"
            >
                <img src="../../../../../../assets/images/rightpanel/sistema-3.svg" alt="outro sistema" />
            </a>
            <a
                href="#"
                class="favorite-item hover:surface-50 surface-0 flex align-items-center justify-content-center border-round-xl m-2 transition-all transition-duration-200"
                style="width: 80px; height: 80px; box-shadow: 0px 0px 2px rgba(41, 50, 65, 0.3), 0px 1px 2px rgba(41, 50, 65, 0.2); background-color: rgba(41, 127, 255, 0.03)"
            >
                <img src="../../../../../../assets/images/rightpanel/sistema-4.svg" alt="outro sistema" />
            </a>
            <a
                href="#"
                class="favorite-item hover:surface-50 surface-0 flex align-items-center justify-content-center border-round-xl m-2 transition-all transition-duration-200"
                style="width: 80px; height: 80px; box-shadow: 0px 0px 2px rgba(41, 50, 65, 0.3), 0px 1px 2px rgba(41, 50, 65, 0.2); background-color: rgba(41, 127, 255, 0.03)"
            >
                <img src="../../../../../../assets/images/rightpanel/sistema-5.svg" alt="outro sistema" />
            </a>
            <a
                href="#"
                class="favorite-item hover:surface-50 surface-0 flex align-items-center justify-content-center border-round-xl m-2 transition-all transition-duration-200 border-1 border-dashed surface-border"
                style="width: 80px; height: 80px; background-color: rgba(41, 127, 255, 0.03)"
            >
                <i class="pi pi-plus"></i>
            </a>
        </div>
    </div>

    <div class="p-0 py-3chat-section chat"></div> -->
</p-sidebar>
