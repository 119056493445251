import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    constructor(private http: HttpClient) {}

    loadAppConfig() {
        return this.http
            .get('/assets/config.json')
            .toPromise()
            .then((config: any) => {
                environment.API_URL = config.API_URL;
                environment.API_SECRET = config.API_SECRET;
            })
            .catch((err) => {
                console.error('Erro ao carregar config.json', err);
            });
    }
}
