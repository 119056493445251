@if(listaGpq.length > 0){

<p-table
    [value]="listaGpq"
    #dt1
    selectionMode="single"
    [paginator]="true"
    [rows]="5"
    [rowsPerPageOptions]="[5, 10, 20]"
    [lazyLoadOnInit]="true"
    [scrollable]="true"
    scrollHeight="200px"
    [tableStyle]="{ 'min-width': '50em' }"
    [globalFilterFields]="[
        'nomeDaEnfermeira',
        'municipio.nome',
        'statusDoDocumento'
    ]"
    dataKey="numeroDoDocumento"
    [totalRecords]="listaGpq.length"
>
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>Município</th>
            <th>Responsável</th>
            <th>Status</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="caption">
        <div class="flex">
            <p-iconField iconPosition="left" class="ml-auto">
                <input
                    pInputText
                    type="text"
                    [(ngModel)]="searchValueGpqs"
                    (input)="onGlobalFilter(dt1, $event)"
                    placeholder="Buscar..."
                />
            </p-iconField>
        </div>
    </ng-template>
    <ng-template pTemplate="body" let-gpq>
        <tr style="height: 60px">
            @if(gpq.statusDoDocumento != 'Ferias' && gpq.statusDoDocumento !=
            'Bloqueado'){
            <td>
                <p-tableRadioButton [value]="gpq" (click)="selecionaGpq(gpq)" />
            </td>
            <td style="cursor: pointer" (click)="navigateToGpq(gpq)">
                {{ gpq.municipio.nome }}
            </td>
            <td style="cursor: pointer" (click)="navigateToGpq(gpq)">
                {{ gpq.nomeDaEnfermeira }}
            </td>
            <td style="cursor: pointer" (click)="navigateToGpq(gpq)">
                <p-tag
                    [value]="getStatusExibicao(gpq.statusDoDocumento)"
                    [severity]="getStatusGpq(gpq)"
                    [style.left.px]="4"
                    [style.top.px]="4"
                />
            </td>
            }@else{
            <td></td>
            <td>{{ gpq.municipio.nome }}</td>
            <td>{{ gpq.nomeDaEnfermeira }}</td>
            <td>
                <p-tag
                    [value]="getStatusExibicao(gpq.statusDoDocumento)"
                    [severity]="getStatusGpq(gpq)"
                    [style.left.px]="4"
                    [style.top.px]="4"
                />
            </td>
            }
        </tr>
    </ng-template>
</p-table>
} @else {

<div class="flex justify-content-center flex-wrap">
    <div
        class="flex align-items-center justify-content-center font-bold border-round m-2 mt-5"
    >
        Nenhum registro de GPQ encontrado para este status.
    </div>
</div>
}
<!-- <p-toast /> -->
<!-- <p-tabView [scrollable]="true" [activeIndex]="2">
    <p-tabPanel header="Em preenchimento">
        <p-table
        [value]="listaGpqEmPreenchimento"
        #dt1
        selectionMode="single"
        [paginator]="true"
        [rows]="5"
        [rowsPerPageOptions]="[5, 10, 20]"
        [lazyLoadOnInit]="true"
        [tableStyle]="{ 'min-width': '50em' }"
        [globalFilterFields]="['nomeDaEnfermeira', 'municipio.nome', 'statusDoDocumento']"
        dataKey="numeroDoDocumento"
        [totalRecords]="listaGpqAprovados.length"
        [lazy]="true"
        [loading]="loading"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th style="width: 25%;">Município</th>
                    <th style="width: 25%;">Responsável</th>
                    <th style="width: 25%;">Status</th>
                    <th style="width: 25%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="caption">
                <div class="flex">
                    <p-iconField iconPosition="left" class="ml-auto">
                        <input
                            pInputText
                            type="text"
                            [(ngModel)]="searchValueGpqs"
                            (input)="onGlobalFilter(dt1, $event)"
                            placeholder="Buscar..." />
                    </p-iconField>
                </div>
            </ng-template>
            <ng-template pTemplate="body" let-gpq>
                <tr>
                    <td><p-tableRadioButton [value]="gpq" (click)="selecionaGpq(gpq)"/></td>
                    <td>{{ gpq.municipio.nome }}</td>
                    <td>{{ gpq.nomeDaEnfermeira }}</td>
                    <td>{{ gpq.statusDoDocumento }}</td>
                    <td>
                        <p-button icon="pi pi-ellipsis-h" class="flex-auto md:flex-initial white-space-nowrap" label="Ver mais" [outlined]="true" (click)="navigateToGpq(gpq)" />
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel> -->
<!-- <p-tabPanel header="Unshipped Orders" class="w-full p-0">
        <ng-template pTemplate="header">
            <div class="pr-2" pBadge value="6" severity="info">
                <h6 class="m-0 mr-2">GPQs</h6>
            </div>
        </ng-template>

        <p-dataView #dv [value]="listaGpq" [lazyLoadOnInit]="true" selectionMode="single" emptyMessage="Este município ainda não possui GPQs">
            <ng-template pTemplate="list" let-listGpq>
                <div class="grid grid-nogutter">
                    <div class="col-12">

                        @if(listaGpq.length != 0){
                            @for(gpq of listGpq; track gpq.numeroDoDocumento){
                                <div class="flex flex-row justify-content-between">
                                    <div class="md:w-10rem relative">
                                        <div>
                                            <span class="font-medium text-secondary text-sm">Responsável: {{gpq.nomeDaEnfermeira}} </span>
                                            <div class="text-lg font-medium text-900 mt-2">{{alterarFormatoData(gpq.dataReferente)}}</div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row md:flex-column align-items-start gap-2">
                                        <div>
                                            <p-tag [value]="getStatusExibicao(gpq.statusDoDocumento)" [severity]="getStatusGpq(gpq)" [style.left.px]="4" [style.top.px]="4" />
                                        </div>
                                    </div>
                                    <div class="flex flex-row-reverse md:flex-row   ">
                                        <p-button icon="pi pi-ellipsis-h" class="flex-auto md:flex-initial white-space-nowrap" label="Ver mais" [outlined]="true" (click)="navigateToGpq(gpq)" />
                                    </div>
                                </div>
                                <p-divider></p-divider>
                            }
                        }
                    </div>
                </div>
            </ng-template>
        </p-dataView>
        <p-menu #menu [popup]="true" [model]="items"></p-menu>
    </p-tabPanel> -->
<!-- <p-tabPanel header="Gest. Municipais">
        <p-table [value]="listaGpqMunicipio"
        #dt2
        selectionMode="single"
        [paginator]="true"
        [rows]="5"
        [rowsPerPageOptions]="[5, 10, 20]"
        [lazyLoadOnInit]="true"
        [tableStyle]="{ 'min-width': '50em' }"
        [globalFilterFields]="['nomeDaEnfermeira', 'municipio.nome', 'statusDoDocumento']"
        dataKey="numeroDoDocumento"
        [totalRecords]="listaGpqAprovados.length"
        [lazy]="true"
        [loading]="loading"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th style="width: 25%;">Município</th>
                    <th style="width: 25%;">Responsável</th>
                    <th style="width: 25%;">Status</th>
                    <th style="width: 25%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="caption">
                <div class="flex">
                    <p-iconField iconPosition="left" class="ml-auto">
                        <input
                            pInputText
                            type="text"
                            [(ngModel)]="searchValueGpqs"
                            (input)="onGlobalFilter(dt2, $event)"
                            placeholder="Buscar..." />
                    </p-iconField>
                </div>
            </ng-template>
            <ng-template pTemplate="body" let-gpq>
                <tr>
                    <td><p-tableRadioButton [value]="gpq" (click)="selecionaGpq(gpq)"/></td>
                    <td>{{ gpq.municipio.nome }}</td>
                    <td>{{ gpq.nomeDaEnfermeira }}</td>
                    <td>{{ gpq.statusDoDocumento }}</td>
                    <td>
                        <p-button icon="pi pi-ellipsis-h" class="flex-auto md:flex-initial white-space-nowrap" label="Ver mais" [outlined]="true" (click)="navigateToGpq(gpq)" />
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel>
    <p-tabPanel header="Para avaliar">
        <p-table [value]="listaGpqFesf"
        #dt3
        selectionMode="single"
        [paginator]="true"
        [rows]="5"
        [rowsPerPageOptions]="[5, 10, 20]"
        [lazyLoadOnInit]="true"
        [tableStyle]="{ 'min-width': '50em' }"
        [globalFilterFields]="['nomeDaEnfermeira', 'municipio.nome', 'statusDoDocumento']"
        dataKey="numeroDoDocumento"
        [totalRecords]="listaGpqAprovados.length"
        [lazy]="true"
        [loading]="loading"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th style="width: 25%;">Município</th>
                    <th style="width: 25%;">Responsável</th>
                    <th style="width: 25%;">Status</th>
                    <th style="width: 25%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="caption">
                <div class="flex">
                    <p-iconField iconPosition="left" class="ml-auto">
                        <input
                            pInputText
                            type="text"
                            [(ngModel)]="searchValueGpqs"
                            (input)="onGlobalFilter(dt3, $event)"
                            placeholder="Buscar..." />
                    </p-iconField>
                </div>
            </ng-template>
            <ng-template pTemplate="body" let-gpq>
                <tr>
                    <td><p-tableRadioButton [value]="gpq" (click)="selecionaGpq(gpq)"/></td>
                    <td>{{ gpq.municipio.nome }}</td>
                    <td>{{ gpq.nomeDaEnfermeira }}</td>
                    <td>{{ gpq.statusDoDocumento }}</td>
                    <td>
                        <p-button icon="pi pi-ellipsis-h" class="flex-auto md:flex-initial white-space-nowrap" label="Ver mais" [outlined]="true" (click)="navigateToGpq(gpq)" />
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel> -->
<!-- <p-tabPanel header="Unshipped Orders" class="w-full p-0">
        <ng-template pTemplate="header">
            <div class="pr-2" pBadge value="6" severity="info">
                <h6 class="m-0 mr-2">Em preenchimento</h6>
            </div>
        </ng-template>
        <p-dataView #dv [value]="listaGpq" [lazyLoadOnInit]="true" selectionMode="single" emptyMessage="Este município ainda não possui GPQs">
            <ng-template pTemplate="list" let-listGpq>
                <div class="grid grid-nogutter">
                    <div class="col-12">

                        @if(listaGpq.length != 0){
                            @for(gpq of listGpq; track gpq.numeroDoDocumento){
                                <div class="flex flex-row justify-content-between">
                                    <div class="md:w-10rem relative">
                                        <div>
                                            <div class="text-lg font-medium text-900 mt-2">Municipio: {{gpq.municipio.nome}} </div>
                                        </div>
                                    </div>
                                    <div class="md:w-10rem relative">
                                        <div>
                                            <span class="font-medium text-secondary text-lg">Responsável: {{gpq.nomeDaEnfermeira}} </span>
                                        </div>
                                    </div>
                                    <div class="flex flex-row md:flex-column align-items-start gap-2">
                                        <div>
                                            <p-tag [style.fontSize]="'lg'" [value]="getStatusExibicao(gpq.statusDoDocumento)" [severity]="getStatusGpq(gpq)" [style.left.px]="4" [style.top.px]="4" />
                                        </div>
                                    </div>
                                    <div class="flex flex-row-reverse md:flex-row   ">
                                        <p-button icon="pi pi-ellipsis-h" class="flex-auto md:flex-initial white-space-nowrap" label="Ver mais" [outlined]="true" (click)="navigateToGpq(gpq)" />
                                    </div>
                                </div>
                                <p-divider></p-divider>
                            }
                        }
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </p-tabPanel> -->
<!-- <p-tabPanel header="Na SESAB">
        <p-table [value]="listaGpqSesab"
        #dt4
        selectionMode="single"
        [paginator]="true"
        [rows]="5"
        [rowsPerPageOptions]="[5, 10, 20]"
        [lazyLoadOnInit]="true"
        [tableStyle]="{ 'min-width': '50em' }"
        [globalFilterFields]="['nomeDaEnfermeira', 'municipio.nome', 'statusDoDocumento']"
        dataKey="numeroDoDocumento"
        [totalRecords]="listaGpqAprovados.length"
        [lazy]="true"
        [loading]="loading"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th style="width: 25%;">Município</th>
                    <th style="width: 25%;">Responsável</th>
                    <th style="width: 25%;">Status</th>
                    <th style="width: 25%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="caption">
                <div class="flex">
                    <p-iconField iconPosition="left" class="ml-auto">
                        <input
                            pInputText
                            type="text"
                            [(ngModel)]="searchValueGpqs"
                            (input)="onGlobalFilter(dt4, $event)"
                            placeholder="Buscar..." />
                    </p-iconField>
                </div>
            </ng-template>
            <ng-template pTemplate="body" let-gpq>
                <tr>
                    <td><p-tableRadioButton [value]="gpq" (click)="selecionaGpq(gpq)"/></td>
                    <td>{{ gpq.municipio.nome }}</td>
                    <td>{{ gpq.nomeDaEnfermeira }}</td>
                    <td>{{ gpq.statusDoDocumento }}</td>
                    <td>
                        <p-button icon="pi pi-ellipsis-h" class="flex-auto md:flex-initial white-space-nowrap" label="Ver mais" [outlined]="true" (click)="navigateToGpq(gpq)" />
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel>
    <p-tabPanel header="Aprovados">
        <p-table [value]="listaGpqAprovados"
        #dt2
        selectionMode="single"
        [paginator]="true"
        [rows]="5"
        [rowsPerPageOptions]="[5, 10, 20]"
        [lazyLoadOnInit]="true"
        [tableStyle]="{ 'min-width': '50em' }"
        [globalFilterFields]="['nomeDaEnfermeira', 'municipio.nome', 'statusDoDocumento']"
        dataKey="numeroDoDocumento"
        [totalRecords]="listaGpqAprovados.length"
        [lazy]="true"
        [loading]="loading"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th style="width: 25%;">Município</th>
                    <th style="width: 25%;">Responsável</th>
                    <th style="width: 25%;">Status</th>
                    <th style="width: 25%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="caption">
                <div class="flex">
                    <p-iconField iconPosition="left" class="ml-auto">
                        <input
                            pInputText
                            type="text"
                            [(ngModel)]="searchValueGpqs"
                            (input)="onGlobalFilter(dt2, $event)"
                            placeholder="Buscar..." />
                    </p-iconField>
                </div>
            </ng-template>
            <ng-template pTemplate="body" let-gpq>
                <tr>
                    <td><p-tableRadioButton [value]="gpq" (click)="selecionaGpq(gpq)"/></td>
                    <td>{{ gpq.municipio.nome }}</td>
                    <td>{{ gpq.nomeDaEnfermeira }}</td>
                    <td>{{ gpq.statusDoDocumento }}</td>
                    <td>
                        <p-button icon="pi pi-ellipsis-h" class="flex-auto md:flex-initial white-space-nowrap" label="Ver mais" [outlined]="true" (click)="navigateToGpq(gpq)" />
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel> -->
<!-- <p-tabPanel header="Unshipped Orders" class="w-full p-0">
        <ng-template pTemplate="header">
            <div class="pr-2" pBadge value="6" severity="info">
                <h6 class="m-0 mr-2">Gestores Municipais</h6>
            </div>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Unshipped Orders" class="w-full p-0">
        <ng-template pTemplate="header">
            <div class="pr-2" pBadge value="6" severity="info">
                <h6 class="m-0 mr-2">Para avaliar</h6>
            </div>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Unshipped Orders" class="w-full p-0">
        <ng-template pTemplate="header">
            <div class="pr-2" pBadge value="6" severity="info">
                <h6 class="m-0 mr-2">Na SESAB</h6>
            </div>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Unshipped Orders" class="w-full p-0">
        <ng-template pTemplate="header">
            <div class="pr-2" pBadge value="6" severity="info">
                <h6 class="m-0 mr-2">Aprovados</h6>
            </div>
        </ng-template>
    </p-tabPanel> -->
<!-- <p-tabPanel header="Messages" class="w-full p-0">
        <ng-template pTemplate="header">
            <div class="pr-2" pBadge value="3" severity="warning">
                <h6 class="m-0 mr-2">Equipe</h6>
            </div>
        </ng-template>

        <p-table #dt [value]="equipe"  [paginator]="true" [scrollable]="true" [scrollDirection]="'vertical'" [scrollHeight]="'300px'" [lazyLoadOnInit]="true" [rows]="5" [tableStyle]="{ 'min-width': '10rem' , 'max-height': '350px'}" [rowsPerPageOptions]="[5, 10, 20]" [expandedRowKeys]="expandedRows" dataKey="dadosCadastrais.cpf">
            <ng-template pTemplate="header">
                <tr>
                  <th style="width: 5rem;"></th>
                  <th style="width: 50%;">Nome</th>
                  <th style="width: 50%;">Função</th>
                  <th style="width: 5rem;">
                    <i class="pi pi-user-plus" style="color: #708090" pTooltip="Adicionar usuário ao serviço" tooltipPosition="left" (click)="exibirFormularioAdicionarUsuario()"></i>
                  </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-membro let-expanded="expanded">
                <tr>
                  <td>
                      <p-button type="button" pRipple [pRowToggler]="membro" [text]="true" [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
                  </td>
                  <td>{{ membro.dadosCadastrais.nome }}</td>
                  <td><p-tag [severity]="getRoleMembro(membro)" [value]="membro.permissao"/></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-membro>
                <tr>
                  <td colspan="7">
                      <div class="p-3">
                        <p-card [style]="{backgroundColor: '#E0E0E0'}">
                            <ng-template pTemplate="content">
                              <div style="align-content: center; display: flex; justify-content: center; flex-direction: column;">
                                <h3>Informações detalhadas</h3>
                                <h6 style="padding-left: 10px;">
                                    <span style="font-weight: 400;">Nome: {{membro.dadosCadastrais.nome}} </span>
                                  </h6>
                                  <h6 style="padding-left: 10px;">
                                    <span style="font-weight: 400;">Função: {{membro.permissao}} </span>
                                  </h6>
                                <h6 style="padding-left: 10px;">
                                    <span style="font-weight: 400;">Contato: {{membro.dadosCadastrais.telefone}} </span>
                                </h6>
                                <h6 style="padding-left: 10px;">
                                    <span style="font-weight: 400;">Email: {{membro.email}} </span>
                                </h6>
                                <h6 style="padding-left: 10px;">
                                    <span style="font-weight: 400;">CPF: {{membro.dadosCadastrais.cpf}} </span>
                                </h6>
                              </div>
                            </ng-template>
                            <ng-template pTemplate="footer">
                              <div class="btn-action">
                                  <div class="left-buttons">
                                    <p-button label="Editar"></p-button>
                                  </div>
                                  <div class="right-button">
                                  </div>
                              </div>
                            </ng-template>
                        </p-card>
                      </div>
                  </td>
                </tr>
            </ng-template>
            </p-table>
        <p-menu #menu2 [popup]="true" [model]="items"></p-menu>
    </p-tabPanel> -->
<!-- </p-tabView> -->
<!-- <p-dialog header="Criar SPNH" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }"> -->
<!-- <span class="p-text-secondary block mb-5">Criar um  SPNH para o município: {{municipio.nome}} ?</span>
    <div class="flex align-items-center gap-3 mb-3">
        <label for="username" class="font-semibold w-6rem">Usuário</label>
        <p-dropdown
        appendTo="body"
        filterPlaceholder="Pesquisar..."
        [options]="usuariosDoMunicipio"
        [(ngModel)]="usuarioSelecionado"
        optionLabel="dadosCadastrais.nome"
        placeholder="Selecione um usuário"
        [style]="{'width':'15rem'}"
        />
    </div> -->
<!-- <div class="flex align-items-center gap-3 mb-3">
        <label for="username" class="font-semibold w-6rem">Função</label>
        <input pInputText id="username" class="flex-auto" [readOnly]="true" [value]="usuarioSelecionado.role == null ? '': usuarioSelecionado.role" autocomplete="off" />
    </div> -->
<!-- <div class="flex align-items-center gap-3 mb-5">
        <label for="email" class="font-semibold w-6rem">Email</label>
        <input pInputText id="email" class="flex-auto" autocomplete="off" />
    </div> -->
<!-- <div class="flex justify-content-end gap-2">
        <p-button label="Cancelar" severity="secondary" (onClick)="visible = false" />
        <p-button label="Confirmar" (onClick)="usuarioSelecionado ? adicionarNovoUsuarioSpnh(municipio.codigo, usuarioSelecionado.email) : false; visible = false" />
    </div> -->
<!-- </p-dialog> -->
