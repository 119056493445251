@if(gpq){
<div class="flex flex-row align-items-center gap-3">

    <span class="custom-tag text-center">
        {{ gpq.municipio.nome }}
    </span>

    <p-divider layout="vertical" />

    <span class="custom-tag">
        {{ dataFormat.formatDataToStringMesAno(gpq.dataReferente) }}
    </span>

    <p-divider layout="vertical" />

    <p-tag
        (click)="redirectToHome()"
        class="pointer"
        rounded="true"
        [severity]="getSeverityStatus(gpq.statusDoDocumento)"
        >{{ getStatusExibicao(gpq.statusDoDocumento) }}</p-tag
    >
</div>
} @else{

    <div class="flex flex-row align-items-center gap-3">

    @if(usuario && (usuario.permissao == "ENFO" || usuario.permissao == "SMS")){
        <span class="custom-tag">
            {{ usuario.municipio.nome }}
        </span>

        <p-divider layout="vertical" />
    }

    <p-tag (click)="redirectToHome()" class="pointer" severity="warning"
        >Nenhuma GPQ selecionada</p-tag
    >
</div>
}
