export enum StatusGpq {
    Criado = 'Criado',
    Submissao = 'Submissão',
    Rejeitado = 'Rejeitado',
    PendenteMunicipio = 'PendenteMunicipio',
    PendenteFesf = 'PendenteFesf',
    PendenteSesab = 'PendenteSesab',
    Aprovado = 'Aprovado',
    Bloqueado = 'Bloqueado',
    Ferias = "Ferias"
}