import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { AppLayoutComponent } from 'src/app/core/components/layout/app.layout.component';
import { AppBreadcrumbComponent } from 'src/app/core/components/breadcrumb/app.breadcrumb.component';
import { AppSidebarComponent } from 'src/app/core/components/layout/sidebar/menu/app.sidebar.component';
import { AppTopbarComponent } from 'src/app/core/components/layout/topbar/app.topbar.component';
import { AppProfileSidebarComponent } from 'src/app/core/components/layout/sidebar/profile/app.profilesidebar.component';
import { AppMenuComponent } from 'src/app/core/components/layout/menu/app.menu.component';
import { AppMenuitemComponent } from 'src/app/core/components/layout/menu/app.menuitem.component';
import { AppConfigModule } from 'src/app/core/components/config/app.config.module';
import { NgOptimizedImage } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputMaskModule } from 'primeng/inputmask';

import {
    UsefulSectorInformationComponent
} from 'src/app/core/components/layout/sidebar/profile/useful-sector-information/useful-sector-information.component';
import {
    FormSectorInformationComponent
} from 'src/app/core/components/layout/sidebar/profile/useful-sector-information/form-sector-information/form-sector-information.component';
import { CardModule } from 'primeng/card';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { TagModule } from 'primeng/tag';
import { DividerModule } from 'primeng/divider';
import { GpqSelecionadoComponent } from './gpq-selecionado/gpq-selecionado.component';
import { ConfigMenuComponent } from './config-menu/config-menu.component';

@NgModule({
    declarations: [
        AppLayoutComponent,
        AppBreadcrumbComponent,
        AppSidebarComponent,
        AppTopbarComponent,
        AppProfileSidebarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        UsefulSectorInformationComponent,
        FormSectorInformationComponent,
        GpqSelecionadoComponent,
        ConfigMenuComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        StyleClassModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        TooltipModule,
        RippleModule,
        RouterModule,
        AppConfigModule,
        ButtonModule,
        NgOptimizedImage,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        CardModule,
        ClipboardModule,
        InputMaskModule,
        ToastModule,
        TagModule,
        DividerModule
    ],
})
export class AppLayoutModule {
}
