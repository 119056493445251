import { Component, Input, OnInit } from '@angular/core';
import { PerfilService } from 'src/app/application/services/perfil-service/perfil.service';
import { LayoutService } from '../../../service/app.layout.service';
import { Router } from '@angular/router';

@Component({
    selector: 'config-menu',
    templateUrl: './config-menu.component.html',
    styleUrl: './config-menu.component.scss',
})
export class ConfigMenuComponent implements OnInit {
    @Input({ required: true }) type: string = 'top-bar';
    buttons: any = [{}];
    urlImage: string = '';

    constructor(
        private perfilService: PerfilService,
        public layoutService: LayoutService,
        private router: Router
    ) {}

    ngOnInit() {
        this.perfilService.imageUrl$.subscribe((url) => {
            this.urlImage = url;
            this.addButtons();
        });
    }

    private addButtons() {
        this.buttons = [
            {
                label: 'Perfil',
                route: '/perfil',
                src: this.urlImage,
                icon: 'pi-user',
            },
            {
                label: 'Sobre',
                route: '/sobre',
                icon: 'pi-info',
            },
            {
                label: 'Informações',
                icon: 'pi-info-circle',
                action: () => this.onSidebarButtonClick(),
            },
            {
                label: 'Configurações',
                icon: 'pi-cog',
                action: () => this.onStyleButtonClick(),
            },
            {
                label: 'Logout',
                icon: 'pi-sign-out',
                action: () => this.onLogout(),
            },
        ];
    }

    onStyleButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onLogout() {
        sessionStorage.clear();
        this.router.navigate(['']).then(() => {
            window.location.reload();
        });
    }
}
