import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { AppLayoutModule } from 'src/app/core/components/layout/app.layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotfoundComponent } from './application/pages/public/notfound/notfound.component';
import { AppConfigService } from './app-config.service';

export function loadAppConfig(appConfig: AppConfigService) {
    return () => appConfig.loadAppConfig();
}

@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    imports: [
        AppRoutingModule,
        CommonModule,
        PaginatorModule,
        AppLayoutModule,
        MessagesModule,
        DropdownModule,
        FormsModule,
        ToastModule,
    ],
    providers: [
        MessageService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadAppConfig,
            deps: [AppConfigService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
