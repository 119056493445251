import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { RelatorioService } from 'src/app/application/services/relatorio-service/relatorio.service';

@Component({
  selector: 'app-header-cards',
  templateUrl: './header-cards.component.html',
  styleUrls: ['./header-cards.component.scss']
})
export class HeaderCardsComponent implements OnInit {
  quantidadeGpqsAprovados: number = 0;
  quantidadeGestantesComVinculacao: number = 0;
  quantidadeDeGravidasEmAcompanhamento: number = 0;
  quantidadeDePartosPrevistos: number = 0;
  quantidadePessoasComRiscos: number = 0;

  hoje: Date = new Date();
  tipoRelatorio: string = 'anual';

  constructor(
    private relatorioService: RelatorioService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.obterDadosDashboard(this.hoje);
  }

  public obterDadosDashboard(data: Date): void {
    this.relatorioService.obterRelatorioDashBoard(data, this.tipoRelatorio).subscribe(
      (relatorio: any) => {
        const metricasMap: { [key: string]: number | undefined } = {};

        // Mapeando corretamente as métricas
        relatorio.metricas.forEach((metrica: { nome: string, valor: number }) => {
          metricasMap[metrica.nome] = metrica.valor;
        });

        // Acessando os valores através do mapeamento correto
        this.quantidadeGpqsAprovados = metricasMap['quantidadeGpqsAprovados'] || 0;
        this.quantidadeGestantesComVinculacao = metricasMap['quantidadeGestantesComVinculacao'] || 0; // Corrigido
        this.quantidadeDeGravidasEmAcompanhamento = metricasMap['quantidadeGravidasEmAcompanhamento'] || 0; // Corrigido
        this.quantidadeDePartosPrevistos = metricasMap['quantidadePartosPrevistos'] || 0; // Corrigido
        this.quantidadePessoasComRiscos = metricasMap['quantidadePessoasComRiscos'] || 0;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível obter os dados do dashboard.' });
      }
    );
  }
}
