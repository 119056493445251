import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Spnh } from '../../models/Spnh';
import { UsuarioResponse } from '../../models/usuarios/UsuarioResponse';

@Injectable({
  providedIn: 'root'
})
export class SpnhService {

  private readonly apiUrl = environment.API_URL + 'spnh'

  private spnhSubject: BehaviorSubject<Spnh | null> = new BehaviorSubject<Spnh | null>(null);
  private ResponsavelEdit: Spnh | null = null;

  private spnh$ = this.spnhSubject.asObservable();

  constructor(private http: HttpClient) {}

  private setHeadersConfiguration() {
    let token = sessionStorage.getItem('token')
    return new HttpHeaders({
      'secret': environment.API_SECRET,
      'Authorization': `Bearer ${token}`
    });
  }

  listarPorId(id: number): Observable<Spnh>{
    return this.http.get<Spnh>(this.apiUrl +"/"+id, {
      headers: this.setHeadersConfiguration()
    })
  }

  listarSpnhs(): Observable<Spnh[]>{
    return this.http.get<Spnh[]>(this.apiUrl, {
      headers: this.setHeadersConfiguration()
    })
  }

  criarSpnh(spnh: Spnh, responsavel: UsuarioResponse){
    let body = {
      codigoDoMunicipio: spnh.municipio.codigo,
      idResponsavel: responsavel.uuid
    }

    return this.http.post(this.apiUrl + '/cadastrar', body, {
      headers: this.setHeadersConfiguration()
    })
  }

  alterarResponsavel(uuid: string, idSpnh: number): Observable<Spnh>{
    return this.http.patch<Spnh>(this.apiUrl + `/alterar-responsavel/${idSpnh}/${uuid}`,{}, {
      headers: this.setHeadersConfiguration()
    })
  }

  adicionarUsuarioEmSpnh(codigoMunicipio: number, email: string) {
    return this.http.patch<any>(this.apiUrl + `/municipio/${codigoMunicipio}/add/${email}`, {} , {
      headers: this.setHeadersConfiguration()
    })
  }

  ativarSpnh(id: number){
    return this.http.patch<any>(this.apiUrl + '/ativar/' + id, {}, {
      headers: this.setHeadersConfiguration()
    })
  }


  ativarSpnhComNovoResponsavel(id: number, uuid: string) { 
    return this.http.patch<any>(`${this.apiUrl}/ativar/${id}/novo-responsavel/${uuid}`, {}, {
        headers: this.setHeadersConfiguration()
    });
  }

  desativarSpnh(id: number){
    return this.http.patch<any>(this.apiUrl + '/desativar/' + id, {}, {
      headers: this.setHeadersConfiguration()
    })
  }

  adicionarMembros(usuarios: UsuarioResponse[], spnh: Spnh){
    let uuids: string[] = [];
    usuarios.forEach(user => uuids.push(user.uuid))

    let body = {
      uuids: uuids
    }

    return this.http.patch<any>(this.apiUrl + '/' + spnh.numeroDoSpnh + "/addMany", body, {
      headers: this.setHeadersConfiguration()
    })
  }

  removerMembro(uuid: string, spnh: Spnh): Observable<void>{
    return this.http.patch<void>(this.apiUrl + `/${spnh.numeroDoSpnh}/remove/${uuid}`, {}, {
      headers: this.setHeadersConfiguration()
    })
  }

  setResponsavelEdit(spnh: Spnh | null){
    this.ResponsavelEdit = spnh;
  }

  getResponsavelEdit(){
    return this.ResponsavelEdit;
  }
}
